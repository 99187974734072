import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { map, catchError, switchMap } from 'rxjs/operators';
import { User } from '../../models/user.model';
import { of, BehaviorSubject, throwError, Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { SessionStorageService } from '../session-storage.service';
import { config } from 'config';
import { ProfileType, ProfileTypeId, UserRole } from 'app/shared/enums/profile-type.enum';

export interface UserRoleResponse {
  profiletypeid: ProfileTypeId;
  profiletype: ProfileType;
}

export interface UpdatePortalUserPayload {
  user_id: number;
  first_name: string;
  username: string;
  profile_type_id: number;
  password?: string;
  last_name?: string;
  location_id?: number | null;
  business_id?: number | null;
}

export interface AddPortalUserPayload {
  firstname: string;
  username: string;
  profile_type_id: number;
  password: string;
  lastname?: string;
  location_id?: number | null;
  business_id?: number | null;
}

export interface AddPharmacyUserResponse {
  user_id: number;
  msg: string;
  status: number;
}

export interface UpdatePortalUserResponse {
  msg: string;
  status: number;
}

@Injectable({
  providedIn: 'root',
})
export class JwtAuthService {
  token;
  isAuthenticated: Boolean;
  user: User = {};
  user$ = new BehaviorSubject<User>(this.user);
  signingIn: Boolean;
  return: string;
  JWT_TOKEN = 'JWT_TOKEN';
  APP_USER = 'EGRET_USER';
  loginResponse: any;

  constructor(
    private st: SessionStorageService,
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.route.queryParams.subscribe(params => {
      this.return = params['return'];
    });
  }

  getDefaultPageUrl() {
    if (this.return) {
      return this.return;
    } else if (this.isDriver()) {
      return 'driver/delivery_pickup';
    } else if (this.isDoctor()) {
      return 'orders/online/clinic_to_action';
    } else if (this.isAdmin() || this.isBusiness() || this.isPortalUser()) {
      return 'orders/online/pending_approval';
    } else if (this.isTelehealthPharmacyUser()) {
      return 'telehealth/telehealth-request';
    }
  }

  public signin(username, password) {
    this.signingIn = true;
    return this.http.post(`${environment.apiURL}login`, { username, password }).pipe(
      map((res: any) => {
        if (res.success.is_two_factor) {
          this.loginResponse = res;
          this.router.navigateByUrl('sessions/verify-user');
        } else {
          this.setRoleAndToken(res);
        }
        this.signingIn = false;
        return res;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  public verifyUser(auth_code) {
    return this.http
      .post(
        `${environment.apiURL}verify_tfa`,
        { auth_code },
        {
          headers: { Authorization: `Bearer ${this.loginResponse.success.token}` },
        }
      )
      .pipe(
        map((res: any) => {
          this.setRoleAndToken(this.getLoginResponse());
          return res;
        }),
        catchError(error => {
          return throwError(error);
        })
      );
  }

  setRoleAndToken(res: any) {
    const { userid, profiletypeid, firstname, ...details } = res.success.user_details;
    const role = res.success.role;
    let user = {
      id: userid,
      displayName: firstname,
      details: details,
      businessid: res.success['_business'],
      locationid: res.success['_location'],
      location: res.success['location '],
      role: role,
    };
    this.setUserAndToken(res.success.token, user, !!res);
  }

  public resetPassword(data) {
    return (
      this.http
        .post(config.apiUrl + 'forgotpassword', data)
        // return this.http.post(`${environment.apiURL}forgot_password`, data)
        .pipe(
          catchError(error => {
            return throwError(error);
          })
        )
    );
  }

  getUserRole(): Observable<UserRoleResponse> {
    return this.http.get<UserRoleResponse>(config.apiUrl + 'get_user_roles').pipe(
      catchError(error => {
        return throwError(error);
      })
    );
  }

  public checkTokenIsValid() {
    return of(this.getUser()).pipe(
      switchMap((profile: User) => {
        // Call the API method to get user role
        return this.getUserRole().pipe(
          map((userRole: UserRoleResponse) => {
            profile.role = userRole.profiletype;
            this.setUserAndToken(this.getJwtToken(), profile, true);
            this.signingIn = false;
            return profile;
          })
        );
      }),
      catchError(error => {
        this.signout();
        return of(error);
      })
    );
  }

  get role(): UserRole {
    return this.getUser().role;
  }

  public signout() {
    this.logout();
    this.setUserAndToken(null, null, false);
    this.router.navigateByUrl('sessions/signin');
  }

  isLoggedIn(): Boolean {
    return !!this.getJwtToken();
  }

  getJwtToken() {
    return this.st.getItem(this.JWT_TOKEN);
  }
  getUser() {
    return this.st.getItem(this.APP_USER);
  }

  getLoginResponse() {
    return this.loginResponse;
  }

  setUserAndToken(token: String, user: User, isAuthenticated: Boolean) {
    this.isAuthenticated = isAuthenticated;
    this.token = token;
    this.user = user;
    this.user$.next(user);
    this.st.setItem(this.JWT_TOKEN, token);
    this.st.setItem(this.APP_USER, this.user);
  }
  isAdmin() {
    return this.user.role === ProfileType.Admin;
  }
  isBusiness() {
    return this.user.role === ProfileType.BusinessUser;
  }

  isDriver() {
    return this.user.role === ProfileType.Driver;
  }

  isDoctor() {
    return this.user.role === ProfileType.Doctor;
  }

  isPharmacyUser() {
    return [
      ProfileType.PortalUser,
      ProfileType.TelehealthPharmacy,
      ProfileType.StandardPharmacy,
    ].includes(this.user.role);
  }

  isPortalUser() {
    return this.user.role === ProfileType.PortalUser;
  }

  isTelehealthPharmacyUser() {
    return this.user.role === ProfileType.TelehealthPharmacy;
  }

  register(data): Observable<any> {
    return this.http.post(`${config.apiUrl1}register`, data).pipe(
      catchError(err => {
        return throwError(err);
      })
    );
  }

  login(username, type) {
    let data = {
      username: username,
      type: type,
    };
    return this.http.post(`${config.apiUrl1}login`, data).pipe(
      catchError(err => {
        return throwError(err);
      })
    );
  }

  logout() {
    this.http
      .post(`${config.apiUrl}logout`, null)
      .pipe(catchError(err => throwError(err)))
      .subscribe();
  }

  referUser(data): Observable<any> {
    return this.http.post(`${config.apiUrl1}referUser`, data).pipe(
      catchError(err => {
        return throwError(err);
      })
    );
  }

  addPharmacyUser(data: AddPortalUserPayload) {
    return this.http
      .post<AddPharmacyUserResponse>(`${config.apiUrl}addPharmacyUser`, data)
      .pipe(
        catchError(err => {
          return throwError(err);
        })
      );
  }

  updatePortalUser(data: UpdatePortalUserPayload) {
    return this.http
      .put<UpdatePortalUserResponse>(
        `${config.apiUrl1}update_portal_user/${data.user_id}`,
        data
      )
      .pipe(
        catchError(err => {
          return throwError(err);
        })
      );
  }
}
