import { Injectable } from '@angular/core';
import {
  USER_VERIFICATION_MAP,
  UserVerificationObj,
  UserVerificationStatus,
} from '../helpers/user-verification-map.helper';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { CamelToSnakeCaseNested, Flag } from '../helpers/utility-types';
import { HttpClient, HttpParams } from '@angular/common/http';
import { config } from 'config';
import { catchError, tap } from 'rxjs/operators';
import { CustomHttpErrorResponse } from '../interfaces/error-response';

export interface User {
  user: UserDetails;
  healthProfile: HealthProfile;
}

export interface UserDetails {
  id: number;
  firstName: string;
  lastName: string;
  userName: string;
  sex: string;
  dob: string;
  mobile: string;
  knownAs: string;
  companyName: string;
  profilePic: string;
  // profilePicS3: string;
  occupation: string;
  emergencyContactName: string;
  emergencyContactNumber: string;
  profileTypeId: number;
  verificationStatus: UserVerificationStatus | null;
}

export interface HealthProfile {
  id: number;
  userId: number;
  gp: Gp;
  gpClinic: GpClinic;
  medicare: Medicare;
  healthCareCard: Card;
  pensionerCard: Card;
  safetyNetCard: Card;
  healthFund: HealthFund;
  ihi: Ihi;
  veteranAffairs: VeteranAffairs;
  hasAllergies: null | string;
  allergies: null | string;
  medicalConditions: null | string;
  isPregnant: null | string;
  isBreastFeeding: boolean;
  expectedDueDate: null | string;
  isLactating: null | string;
  isSmoking: boolean;
  isOnContraception: null | string;
  nameOfContraception: null | string;
  weight: null | string;
  medicalProcedure: null | string;
  descriptionForProcedure: null | string;
  procedureYear: null | string;
  isParentUser: boolean;
  profileRelation: string;
}

export interface Gp {
  gpName: null;
  gpLink: null;
}

export interface GpClinic {
  name: null;
  addressLine1: null;
  addressLine2: null;
  suburb: null;
  state: null;
  postcode: null;
  phone: null;
}

export interface Card {
  cardNumber: null;
}

export interface HealthFund {
  name: null;
  membershipNumber: null;
  membershipReference: null;
}

export interface Ihi {
  referenceID: null;
  expiry: null;
}

export interface Medicare {
  status: number;
  cardNumber: null;
  reference: string;
  expiry: string;
}

export interface VeteranAffairs {
  cardNumber: null;
  expiry: null;
}

export interface Gp {
  gpName: null;
  gpLink: null;
}

export interface GpClinic {
  name: null;
  addressLine1: null;
  addressLine2: null;
  suburb: null;
  state: null;
  postcode: null;
  phone: null;
}

export interface DateOfBirth {
  day: string;
  month: string;
  year: string;
}

export type FormattedDateOfBirth =
  `${number}${number}-${number}${number}-${number}${number}${number}${number}`;

export interface CheckVerificationParams {
  first_name: string;
  last_name: string;
  dob: DateOfBirth;
}

export interface UserResponse {
  user: CamelToSnakeCaseNested<User['user']> & {
    profile_pic_s3: string;
  };
  health_profile?: CamelToSnakeCaseNested<User['healthProfile']>;
}

export interface HealthFund {
  name: null;
  membershipNumber: null;
  membershipReference: null;
}

export interface Ihi {
  referenceID: null;
  expiry: null;
}

export interface Medicare {
  status: number;
  cardNumber: null;
  reference: string;
  expiry: string;
}

export interface VeteranAffairs {
  cardNumber: null;
  expiry: null;
}

export interface GetUserParams {
  with_health_profile?: Flag;
}

export interface UpdateUserProfileParams {}

export interface UpdateUserVerificationParams
  extends Pick<User['user'], 'firstName' | 'lastName'> {
  password: string;
  status: UserVerificationStatus;
  userId: number;
  dob: DateOfBirth;
  notes: string;
}

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private currentUser$ = new BehaviorSubject<UserResponse | null>(null);
  currentUserObs = this.currentUser$.asObservable();

  constructor(private http: HttpClient) {}

  get currentUser() {
    return this.currentUser$.getValue();
  }

  private setCurrentUser(user: UserResponse) {
    this.currentUser$.next(user);
  }

  verificationObj(status: UserVerificationStatus | null): UserVerificationObj {
    if (typeof status !== 'number' || status === null) return USER_VERIFICATION_MAP[0];

    return USER_VERIFICATION_MAP[status];
  }

  getUser(id: number, params: GetUserParams = {}) {
    return this.http
      .get<UserResponse>(`${config.apiUrl1}user/${id}`, {
        params: params as HttpParams,
      })
      .pipe(
        tap(res => this.setCurrentUser(res)),
        catchError(err => throwError(err))
      );
  }

  updateUser(params: UpdateUserProfileParams) {
    return this.http
      .post<{ response: string; status: number }>(`${config.apiUrl1}update_user_profile`, {
        ...params,
      })
      .pipe(catchError(err => throwError(err)));
  }

  checkUserVerification(params: CheckVerificationParams) {
    return this.http
      .post<{ result: number }>(`${config.apiUrl1}check_user_verification`, {
        ...params,
      })
      .pipe(catchError(err => throwError(err)));
  }

  splitDob(date: string): DateOfBirth {
    const [day, month, year] = date.split('-');
    return { day, month, year };
  }

  updateUserVerification(data: UpdateUserVerificationParams) {
    return this.http
      .put<{ data: []; status: string | number }>(
        `${config.apiUrl1}user/manually_verify/${data.userId}`,
        {
          first_name: data.firstName,
          last_name: data.lastName,
          dob: {
            day: data.dob.day,
            month: data.dob.month,
            year: data.dob.year,
          },
          password: data.password,
          status: data.status.toString(),
          notes: data.notes,
        }
      )
      .pipe(catchError(err => throwError(err)));
  }
}
