import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { config } from 'config';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { createHttpParams } from '../helpers/utils';

export interface Setting<T = string> {
  systemsettingid: number;
  systemsettingcategoryid: string;
  settingkeyname: string;
  settingvalue: T;
  createddate: string;
  createdby: string;
  lastupdated: string;
  lastupdatedby: string;
  expirydate: string;
  expiredby: string;
  systemsettingstatus: number;
  settingimage: string;
  settingimage_s3: string;
  isUpdating?: boolean; // Added for frontend purpose to track whether the setting is being updated
}
export interface SettingsResponse {
  settings: {
    [key: string]: Setting
  }
}


export interface SystemSettingCategory {
  category_id: number;
  category_name: string;
  display_name: string;
}

export interface SystemSettingsCategoriesPayload {
  category_id?: number;
  editable?: number | null;
  search_term?: string;
}

export interface SystemSettingsCategoriesResponse {
  categories: {
    [key: number]: SystemSettingCategory
  };
  status: number;
}

export interface UpdateSystemSettingPayload {
  setting_id: number;
  setting_value: string | null;
}

export interface GetSystemSettingsPayload {
  categoryid?: number;
  editable?: 0 | 1;
  keyname?: string;
}

@Injectable({
  providedIn: 'root'
})
export class SystemSettingsService {

  constructor(private http: HttpClient) { }

  getSystemSettings(payload: GetSystemSettingsPayload): Observable<SettingsResponse['settings']> {
    const params = createHttpParams(payload);

    return this.http.get<SettingsResponse>(config.apiUrl + 'getSystemSettings', {
      params,
      responseType: "json"
    }).pipe(
      map(data => data.settings),
      catchError(err => throwError(err))
    )
  }

  getSystemSettingsCategories(payload?: SystemSettingsCategoriesPayload) {
    const params = createHttpParams(payload);

    return this.http.get<SystemSettingsCategoriesResponse>(config.apiUrl1 + 'get_system_setting_categories', {
      params
    }).pipe(
      map(data => data.categories),
      catchError(err => throwError(err))
    )
  }

  updateSystemSetting(payload: UpdateSystemSettingPayload) {

    return this.http.post<{ msg: string; status: number }>(config.apiUrl1 + 'update_system_setting', payload).pipe(
      catchError(err => throwError(err))
    )
  }
}
