import { Routes } from '@angular/router';
import { AdminLayoutComponent } from './shared/components/layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';
import { UserRoleGuard } from './shared/guards/user-role.guard';
import { AccessDeniedComponent } from './views/access-denied/access-denied.component';
import { ComingSoonComponent } from './views/coming-soon/coming-soon/coming-soon.component';
import { SupportComponent } from './views/support/support.component';
import { ProgramComponent } from './views/programs/program/program.component';
import { DriverLayoutComponent } from './shared/components/layouts/driver-layout/driver-layout.component';
import { DeliveryPickupDriverOrdersComponent } from './views/orders/orders-table/orders-table.component';
import { ViewOrdersComponent } from './views/orders/view-orders/view-orders.component';
import { ProfileType } from './shared/enums/profile-type.enum';
export const rootRouterConfig: Routes = [
  {
    path: '',
    redirectTo: 'orders/online/pending_approval',
    pathMatch: 'full',
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'sessions',
        loadChildren: () => import('./views/sessions/sessions.module').then(m => m.SessionsModule),
        data: { title: 'Session' }
      }
    ]
  },
  {
    path: 'access-denied',
    component: AccessDeniedComponent,
  },
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [UserRoleGuard],
    canActivateChild: [UserRoleGuard],
    data: { role: [ProfileType.Admin, ProfileType.PortalUser, ProfileType.TelehealthPharmacy, ProfileType.BusinessUser, ProfileType.Doctor] },
    children: [
      {
        path: 'orders',
        loadChildren: () => import('./views/orders/orders.module').then(m => m.OrdersModule),
        data: { title: 'orders', breadcrumb: 'Orders' },
      },
      {
        path: 'stores',
        loadChildren: () => import('./views/stores/stores.module').then(m => m.StoresModule),
      },
      // {
      //   path: 'others',
      //   loadChildren: () => import('./views/others/others.module').then(m => m.OthersModule),
      // },
      {
        path: 'search',
        loadChildren: () => import('./views/search-view/search-view.module').then(m => m.SearchViewModule),
      },
      {
        path: 'admin',
        canActivateChild: [UserRoleGuard],
        data: { role: [ProfileType.Admin] },
        loadChildren: () => import('./views/admin/admin.module').then(m => m.AdminModule)
      },
      {
        path: 'products',
        loadChildren: () => import('./views/products/products.module').then(m => m.ProductsModule),
      },
      // {
      //   path: 'users-old',
      //   loadChildren: () => import('./views/users-old/users.module').then(m => m.UsersModule)
      // },
      {
        path: 'users',
        data: { role: [ProfileType.Admin] },
        loadChildren: () => import('./views/users/customers.module').then(m => m.CustomersModule),
      },
      {
        path: 'telehealth',
        loadChildren: () => import('./views/telehealth/telehealth.module').then(m => m.TelehealthModule),
      },
      {
        path: 'resources',
        loadChildren: () => import('./views/programs/programs.module').then(m => m.ProgramsModule),
      },
      {
        path: 'resources/:programmeSlug',
        component: ProgramComponent,
      },
      {
        data: { role: [ProfileType.Admin, ProfileType.BusinessUser] },
        canActivate: [UserRoleGuard],
        path: 'resources/:programmeSlug/:locationId',
        component: ProgramComponent
      },
      // {
      //   path: 'invite',
      //   component: CustomersComponent,
      // },
      {
        path: 'reports',
        // component: ReportsComponent,
        // canActivate: [UserRoleGuard],
        loadChildren: () => import('./views/reports/reports.module').then(m => m.ReportsModule),
      },
      {
        path: 'dashboard',
        // component: ChartComponent,
        loadChildren: () => import('./views/dashboard-2/dashboard-2.module').then(m => m.Dashboard2Module)
      },
      // {
      //   path: 'my-programs',
      //   loadChildren: () => import('./views/programs/programs-routing.module').then(m => m.ProgramsRoutingModule)
      // },
      {
        path: 'coming-soon',
        component: ComingSoonComponent,
      },
      {
        path: 'support',
        component: SupportComponent,
      },
    ]
  },
  {
    path: 'driver',
    redirectTo: 'driver/delivery_pickup',
    pathMatch: 'full'
  },
  {
    path: 'driver',
    component: DriverLayoutComponent,
    canActivate: [UserRoleGuard],
    data: { role: [ProfileType.Driver] },
    children: [
      {
        path: 'delivery_pickup',
        component: DeliveryPickupDriverOrdersComponent,
        data: { title: 'DeliveyPickup', breadcrumb: 'Delivery & Pickup' },
      },
      {
        path: 'vieworders/:id',
        component: ViewOrdersComponent,
        data: { title: "view", breadcrumb: 'view' },
      },
      {
        path: 'coming-soon',
        component: ComingSoonComponent,
      },
      {
        path: 'support',
        component: SupportComponent,
      },
    ]
  },
  {
    path: '**',
    redirectTo: 'sessions/404'
  }
];

