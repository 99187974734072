import { Component, ViewChild, ElementRef, Inject, AfterViewInit } from '@angular/core';
import { OnInit } from '@angular/core';
import {
  AssignableMedicationPayload,
  AssignableMedicationPayloadWithEnv,
  DataOrderService,
  DeliveryPartner,
  OrderDetail,
  OrderStatus,
  Subscription,
} from 'app/shared/services/data-order.service';
import { ActivatedRoute, Params } from '@angular/router';
import { Tile } from 'app/shared/services/data-order.service';
import { OrderList, Scripts } from 'app/shared/services/data-order.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogItemComponent } from '../dialog-item/dialog-item.component';
import { JwtAuthService } from 'app/shared/services/auth/jwt-auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  DEFAULT_PARTNER,
  DialogDeliveryComponent,
} from '../dialog-delivery/dialog-delivery.component';
import { DialogCancelComponent } from '../dialog-cancel/dialog-cancel.component';
import { DialogStockComponent } from '../dialog-stock/dialog-stock.component';
import { AlertSnackbarComponent } from 'app/shared/components/alert-snackbar/alert-snackbar.component';
import { ProductsService } from 'app/shared/services/products/products.service';
import { Router } from '@angular/router';
import { UberService } from 'app/shared/services/uber/uber.service';
import { DoorDashService } from 'app/shared/services/delivery-partners/doordash.service';
import { InvoiceService } from 'app/shared/services/invoice.service';
import { DialogRefundComponent } from '../dialog-refund/dialog-refund.component';
import { animate, state, style, transition, trigger } from '@angular/animations';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { LOCATION_STATUS_MAP } from 'app/shared/helpers/location-status-map.helper';
import {
  ADMIN_OPTIONS,
  MASTER_STATUSES,
  OrderActions,
  OrderOptions,
  OrderStatusEnum,
  USER_OPTIONS,
} from 'app/shared/helpers/status-options.helper';
import { DialogManualPaymentComponent } from '../dialog-manual-payment/dialog-manual-payment.component';
import { DialogReorderScriptsComponent } from '../dialog-reorder-scripts/dialog-reorder-scripts.component';

import { PAYMENT_STATUS_MAP, PaymentStatusEnum } from 'app/shared/helpers/payment-status-map.helper';
import { PAPER_SCRIPT } from 'app/shared/constants/prescription-type.constants';
import { DialogTelehealthPaymentComponent } from '../dialog-telehealth-payment/dialog-telehealth-payment.component';
import { DialogZeroPriceComponent } from '../dialog-zeroprice/dialog-zeroprice.component';
import { SurveyData } from 'app/shared/models/surveyData.model';
import { DialogVariantsComponent } from '../dialog-variants/dialog-variants.component';
import { DialogGenMedCertComponent } from '../dialog-gen-med-cert/dialog-gen-med-cert.component';
import { DialogConfirmationComponent } from '../dialog-confirmation/dialog-confirmation.component';
import { Entitlement } from 'app/shared/interfaces/entitlement';
import moment from 'moment';
import { DialogCancelOrderComponent } from '../dialog-cancel-order/dialog-cancel-order.component';
import { ProfileType } from 'app/shared/enums/profile-type.enum';
import { LinksComponent } from '../links/links.component';
import {
  DialogAssignMedicationComponent,
  HandleValue,
} from '../dialog-assign-medication/dialog-assign-medication.component';
import { Environment } from 'app/shared/interfaces/utility-types';
import { DialogCreateFollowUpRequestComponent } from '../dialog-create-follow-up-request/dialog-create-follow-up-request.component';
import { shouldCreateFollowUpAppointment } from 'app/shared/helpers/utils';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { AlertService } from 'app/shared/services/alert/alert.service';
import { PortalSettingsService } from "app/shared/services/portal-settings.service";

export interface State {
  flag: string;
  name: string;
  population: string;
}

export const USER_CHAT_ROLE_MAP = {
  [ProfileType.Admin]: 'Support',
  [ProfileType.BusinessUser]: 'Brand User',
  [ProfileType.PortalUser]: 'Pharmacy',
  [ProfileType.TelehealthPharmacy]: 'Pharmacy',
};

type PaymentStatus = number | string;

type MenuItem = {
  name: string;
  color: string;
  sub: MenuItem[] | string[];
};

export interface TileClasses {
  'pharmacy-instructions'?: boolean;
  'order-remarks-tile'?: boolean;
}

export interface TileStyles {
  'background-color'?: string;
}

@Component({
  selector: 'app-view-orders',
  templateUrl: './view-orders.component.html',
  styleUrls: ['./view-orders.component.scss'],
  animations: [
    trigger('showMore', [
      state('show', style({ maxHeight: '100px' })),
      state('hide', style({ maxHeight: '20px' })),
      transition('show <=> hide', [animate('350ms')]),
    ]),
  ],
})
export class ViewOrdersComponent implements OnInit, AfterViewInit {
  CustomerProfileForm: FormGroup;
  OrderNoteForm: FormGroup;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('itemEditTable') itemEditTable: ElementRef;
  @ViewChild('otcSearchInput') otcSearchInput: ElementRef;
  resultsLength = 0;
  menuItems: MenuItem[] = [];
  initialMenuItems = [
    // {
    //   name: 'Edit Address',
    //   color: 'black'
    // },
    // {
    //   name: 'Print Order',
    // },
    {
      name: 'Change Status',
      color: 'black',
      sub: [],
    },
  ];
  editDelivery;

  viewItems: Tile[];
  orderList: OrderList[];
  locationId: number;
  serviceScript: any;
  orderHistory: any;
  scriptData: Scripts;
  editshow = 0;
  showMore = false;
  showFull = false;
  showrefund = 0;
  showMoreActions = false;
  showMoreActionsButton = true;
  showConfirmPharmacyNotes = false;
  showConfirmAdminNotes = false;
  showConfirmCurrentOwner = false;

  otcItemSearch = new FormControl('');
  filteredOtcItemResults: Observable<any>;

  currentStatus = '';
  promoCode = '';
  uberOrder: boolean;

  itemsEdited = []; //for items that have been reduced or deleted
  itemsEdited1 = []; //for items that have had a price change or increase in quantity
  loading: boolean = false;

  originalColumns: string[] = ['Image', 'ItemDetails', 'UnitPrice', 'QTY', 'Amount'];
  displayedColumns = [...this.originalColumns];
  scriptColumns = [...this.originalColumns];
  scripts = [];
  items = [];
  getPrint: any;
  textareaValue = '';
  subtotal = 0;
  a_d_i = '';
  d_i = '';
  p_i = '';
  o_i = '';
  a_n = '';
  admin_notes = '';
  s_n = '';
  selectedOption = '';

  serviceRequest: any;
  items1 = [];
  scripts1 = [];
  addItems = 0;
  searchData = [];
  searchWord = '';
  newDeliveryFee = '';
  newServiceFee = '';
  newOrderTotal = '';
  admin = '';
  catalogue = {
    otcCatalogueLocationId: 0,
  };

  searchingItem = false;

  adminNotes = new FormControl('');
  pharmacyOrderNotes = new FormControl('');
  currentOwner = new FormControl('');

  //Custoemr Order History
  displayedColumns1: string[] = [
    'createddate',
    'orderid',
    'totalStatus',
    'ordertype',
    'orderstatus',
    'action',
  ];
  statusOptions: OrderOptions = null;
  tabIndex = 0;
  paymentStatuses;
  logo = 'assets/images/logomain.png';

  //master order status array
  orderStatusArr: string[] = MASTER_STATUSES;
  isDriverUser: boolean;
  reorderScripts: any[];
  subscription: Subscription = null;
  documentClickListener: () => void;
  hash: string | null = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private dataOrder: DataOrderService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    public jwtAuth: JwtAuthService,
    private product: ProductsService,
    private uberService: UberService,
    private doorDashService: DoorDashService,
    private invoiceService: InvoiceService,
    private elementRef: ElementRef,
    private portalSettingsService: PortalSettingsService,
    private alertService: AlertService,
  ) {
    this.admin = this.jwtAuth.isAdmin() ? '1' : '0';
    this.isDriverUser = this.jwtAuth.getUser().role === 'Driver';
    if (this.admin === '1') {
      this.statusOptions = ADMIN_OPTIONS;
    } else {
      this.statusOptions = USER_OPTIONS;
    }
    this.paymentStatuses = PAYMENT_STATUS_MAP;
    this.filteredOtcItemResults = this.otcItemSearch.valueChanges.pipe(
      tap(i => (i.length < 3 ? (this.searchingItem = false) : (this.searchingItem = true))),
      debounceTime(400),
      distinctUntilChanged(),
      switchMap(val => {
        if (typeof val === 'object' || val.length < 3) return of([]); //prevents api from being called on item selected
        return this.dataOrder
          .searchData(
            val,
            this.serviceScript.orderData.locationid,
            this.serviceScript.orderData.Source
          )
          .pipe(
            tap(() => (this.searchingItem = false)),
            map(response => response.drug_details)
          );
      })
    );

    this.portalSettingsService.portalSettings.subscribe(settings => {
      this.logo = settings.menu_logo
    })
  }

  ngOnInit(): void {
    this.route.params.subscribe((param: Params) => {
      const orderid = param['id'];
      this.getOrderDetails(orderid);
    });

    this.route.fragment.subscribe((fragment: string | null) => {
      this.hash = fragment;
    });
  }

  ngAfterViewInit() {
    // this.initMenuItems()
  }
  isTelehealthOrder() {
    return (
      this.admin === '0' &&
      this.serviceScript.orderData.order_class?.includes('Telehealth') &&
      !this.serviceScript.orderData.Source?.includes('Quitmate')
    );
  }


  canShowDocuments() {
    return !this.isDriverUser && this.serviceScript.orderData.order_class?.includes('Telehealth');
  }

  isInStore(paymentStatus: PaymentStatus) {
    return paymentStatus === PaymentStatusEnum.IN_STORE_PAYMENT;
  }

  gen_cert_allow(item) {
    return (
      this.admin === '1' &&
      item.is_medical_cert_item &&
      this.serviceScript.orderData.orderstatus == 'Clinic To Action'
    );
  }

  view_cert(link) {
    window.open(link);
  }

  getOrderDetails(orderid) {
    this.dataOrder.getOrderdetails(orderid).subscribe(
      res => {
        // console.log(res);
        this.serviceScript = res;
        this.uberOrder = this.serviceScript.orderData.deliverypartnername
          ? this.serviceScript.orderData.deliverypartnername.includes('Uber Eats')
          : false;
        this.viewItems = this.dataOrder.tiles;
        this.orderList = this.dataOrder.listItems;
        this.catalogue = res['catalogue'];
        this.currentStatus = res['orderData']['orderstatus'];
        this.promoCode = res['orderData']['promoCode'];
        this.initMenuItems();
        this.configureChangeStatus();

        if (res['orderData']['orderPaidAmount'] <= 0) this.showrefund = 1;

        if (this.currentStatus === 'Pending Cancellation' && this.admin == '0')
          this.showMoreActionsButton = false;

        //set delivery instructions
        this.a_d_i = this.serviceScript.orderData.AdditionalDeliveryInstructions;
        this.d_i = this.serviceScript.orderData.deliveryinstructions;

        //separate otc items from script itesm
        this.scripts = this.serviceScript.scriptslist.filter(item => item.isscript === 1);
        this.items = this.serviceScript.scriptslist.filter(item => item.isscript === 0);
        this.items.forEach(item => {
          const markupAmount = item.PlatformMarkupAmount
            ? parseFloat(item.PlatformMarkupAmount) / parseInt(item.drugpack)
            : 0;
          item.PlatformMarkupAmount = markupAmount;
          item.pharmacyUnitPrice = Math.abs(
            parseFloat(item.originalprice) - markupAmount
          ).toFixed(2);
        });

        this.scripts.forEach(item => {
          const markupAmount = item.PlatformMarkupAmount
            ? parseFloat(item.PlatformMarkupAmount) / parseInt(item.drugpack)
            : 0;
          item.PlatformMarkupAmount = markupAmount;
          item.pharmacyUnitPrice = Math.abs(
            parseFloat(item.originalprice) - markupAmount
          ).toFixed(2);
        });
        //set fees and order pices
        this.subtotal =
          this.serviceScript.orderData.subTotal ||
          this.serviceScript.scriptslist
            .reduce(
              (amount, item) =>
                amount + parseFloat(item.originalprice) * parseInt(item.drugpack),
              0
            )
            .toFixed(2);
        this.newDeliveryFee = this.serviceScript.orderData.deliveryfee || '0.00';
        this.newServiceFee = this.serviceScript.orderData.customerServiceFee || '0.00';

        //set order notes
        this.adminNotes.setValue(this.serviceScript.orderData.medmateOrderNotes);
        this.pharmacyOrderNotes.setValue(this.serviceScript.orderData.pharmacyOrderNotes);
        this.currentOwner.setValue(this.serviceScript.orderData.currentOwner);
        this.dataOrder.setViewOrder(this.serviceScript);

        this.subscription = res.orderData.subscription;

        this.setupDisplayColumns();

        //set order history
        this.orderHistory = new MatTableDataSource(this.serviceScript.orderHistory);
        // console.log(this.orderHistory);
        this.orderHistory.sort = this.sort;
        this.orderHistory.paginator = this.paginator;

        this.handleHash();
      },
      err => {
        this.router.navigate(['../../access-denied']);
      }
    );
  }

  clearMenuItems() {
    this.menuItems = [
      {
        name: 'Change Status',
        color: 'black',
        sub: [],
      },
    ];
  }

  //set 'more' menu items
  initMenuItems() {
    const menuItems = [...this.initialMenuItems];
    const orderStatus: OrderStatus = this.serviceScript.orderData.orderstatus;
    const partner = this.serviceScript.orderData.deliverypartnername;

    menuItems.splice(this.menuItems.length - 1, 0, {
      name: 'Send Invoice',
      color: 'black',
      sub: [],
    });

    if (!this.uberOrder) {
      menuItems.splice(0, 0, { name: 'Edit Address', color: 'black', sub: [] });
    }

    if (partner === 'DoorDash' && this.admin === '1') {
      menuItems.splice(menuItems.length - 1, 0, {
        name: 'Recall DoorDash',
        color: 'black',
        sub: [],
      });
    } else if (partner === 'Uber Eats' && this.admin === '1') {
      menuItems.splice(menuItems.length - 1, 0, {
        name: 'Check Delivery Status',
        color: 'black',
        sub: [],
      });
    }

    if (this.serviceScript.orderData.Source === 'Medmate-Store' && this.admin === '1') {
      menuItems.splice(menuItems.length - 1, 0, {
        name: 'Send Invoice',
        color: 'black',
        sub: [],
      });
    }

    if (this.admin === '1') {
      menuItems.splice(menuItems.length - 1, 0, { name: 'Links', color: 'black', sub: [] });

      if (orderStatus === OrderStatusEnum.ClinicToAction) {
        menuItems.splice(menuItems.length - 1, 0, {
          name: 'Assign Medication',
          color: 'black',
          sub: [],
        });
      }
      menuItems.splice(menuItems.length - 1, 0, { name: 'Refund', color: 'black', sub: [] });
      menuItems.splice(menuItems.length - 1, 0, {
        name: 'Change Pharmacy',
        color: 'black',
        sub: [],
      });
    }

    //check if order status satisfies the given array
    if (
      (['Awaiting Delivery', 'Return', 'Completed', 'In Transit'] as OrderStatus[]).includes(
        orderStatus
      )
    ) {
      menuItems.splice(menuItems.length - 1, 0, {
        name: 'Print Label',
        color: 'black',
        sub: [],
      });
    }

    let changeStatusMenuItem = menuItems.find(i => i.name === 'Change Status');

    const sub = changeStatusMenuItem.sub as string[];
    if (sub.includes('Cancel')) {
      menuItems.splice(menuItems.length, 0, {
        name: 'Cancel Order',
        color: '#ff3d57',
        sub: [],
      });
    }

    if (
      this.admin === '1' &&
      this.serviceScript.survey_data.length > 0 &&
      this.serviceScript.orderData.orderstatus === 'Clinic To Review'
    ) {
      menuItems.splice(menuItems.length, 0, {
        name: 'Send Request Email',
        color: 'black',
        sub: [],
      });
    }
    if (shouldCreateFollowUpAppointment(orderStatus, this.serviceScript.orderData)) {
      menuItems.splice(0, 0, {
        name: OrderActions.CreateFollowUpAppointment,
        color: 'black',
        sub: []
      });
    }
    this.menuItems = menuItems;
  }

  canShowPrintOrderButton(): boolean {
    const orderStatus = this.serviceScript.orderData.orderstatus;
    return [OrderStatusEnum.Process, OrderStatusEnum.AwaitingDelivery, OrderStatusEnum.InTransit, OrderStatusEnum.Return, OrderStatusEnum.Completed].includes(orderStatus);
  }

  configureChangeStatus() {
    let changeStatusMenuItem = this.menuItems.find(i => i.name === 'Change Status');
    if (
      this.currentStatus == 'New' &&
      (this.serviceScript.orderData.paymentstatus == 0 ||
        this.serviceScript.orderData.paymentstatus == null)
    ) {
      changeStatusMenuItem.sub = ['Waiting For Review'];
    } else if (
      this.currentStatus == 'New' &&
      (this.serviceScript.orderData.paymentstatus == 0 ||
        this.serviceScript.orderData.paymentstatus == null)
    ) {
      changeStatusMenuItem.sub = ['Process'];
    } else if (
      this.currentStatus == 'New' &&
      this.serviceScript.orderData.paymentstatus != null &&
      this.serviceScript.orderData.paymentstatus != 0
    ) {
      changeStatusMenuItem.sub = ['Waiting For Review'];
    } else if (
      this.currentStatus == 'Rejected' &&
      (this.serviceScript.orderData.paymentstatus == 0 ||
        this.serviceScript.orderData.paymentstatus == null) &&
      this.serviceScript.orderData.ordertype == 'Delivery'
    ) {
      changeStatusMenuItem.sub = ['Process', 'Cancel'];
    } else if (this.serviceScript.orderData.deliverypartnername == 'Uber Eats') {
      changeStatusMenuItem.sub = ['Cancel', 'Awaiting Delivery'];
    } else {
      changeStatusMenuItem.sub = this.filterCopyArray(
        this.statusOptions[this.currentStatus].actions,
        true
      );

      // This code manages the conditional display of the "Check Delivery Status" menu item
      // and elevates it one level up in the menu structure when certain conditions are met.
      const changeStatusActions = this.filterCopyArray(
        this.statusOptions[this.currentStatus].actions,
        true
      );
      if (changeStatusActions.includes('Check Delivery Status')) {
        if (
          this.menuItems.findIndex(menuItem => menuItem.name === 'Check Delivery Status') ===
          -1
        ) {
          this.menuItems.splice(this.menuItems.length - 1, 0, {
            name: 'Check Delivery Status',
            color: 'black',
            sub: [],
          });
        }
        changeStatusMenuItem.sub = changeStatusMenuItem.sub.filter(
          status => status !== 'Check Delivery Status'
        );
      } else {
        this.menuItems = this.menuItems.filter(
          menuItem => menuItem.name !== 'Check Delivery Status'
        );
      }

      if (
        this.serviceScript.orderData.ordertype === 'Pickup' ||
        this.serviceScript.orderData.ordertype === 'Pick Up'
      ) {
        if (changeStatusMenuItem.sub.indexOf('Awaiting Delivery') !== -1) {
          changeStatusMenuItem.sub[changeStatusMenuItem.sub.indexOf('Awaiting Delivery')] =
            'Awaiting Pickup';
        }
        if (changeStatusMenuItem.sub.indexOf('In Transit') !== -1) {
          changeStatusMenuItem.sub.splice(changeStatusMenuItem.sub.indexOf('In Transit'), 1);
        }
      }
      //remove change pharmacy from change status sub menu
      //TODO find out if there is a better way to do this
      if (changeStatusMenuItem.sub.indexOf('Change Pharmacy') !== -1) {
        changeStatusMenuItem.sub.splice(
          changeStatusMenuItem.sub.indexOf('Change Pharmacy'),
          1
        );
      }
    }
    // console.log('status', changeStatusMenuItem)
  }

  //filter order status array for unwanted conditions
  filterCopyArray(arr: string[], include = false) {
    if (!include) return [...this.orderStatusArr].filter(item => !arr.includes(item));
    return [...this.orderStatusArr].filter(item => arr.includes(item));
  }

  setupDisplayColumns() {
    const otcColumns = [...this.originalColumns];
    const scriptColumns = [...this.originalColumns];

    try {
      const surveyData: SurveyData['survey_data'] = JSON.parse(
        this.serviceScript.survey_data?.[0]?.survey_data
      );
      const surveyDataObj = surveyData.survey_data;
      if (this.dataOrder.isTelehealthReferralOrder(surveyDataObj)) {
        scriptColumns.splice(scriptColumns.indexOf('UnitPrice'), 1);
        scriptColumns.splice(scriptColumns.indexOf('Amount'), 1);
      }
    } catch (error) {
      // console.error('Error parsing survey data:', error);
    }

    this.scriptColumns = scriptColumns;
    this.displayedColumns = otcColumns;
  }

  CustomerProfileForms() {
    this.CustomerProfileForm = this._formBuilder.group({
      firstName: new FormControl(
        (this.serviceScript && this.serviceScript.orderData.fname) || ''
      ),
      lastName: new FormControl(
        (this.serviceScript && this.serviceScript.orderData.lname) || ''
      ),
      allergies: new FormControl(
        (this.serviceScript && this.serviceScript.healthprofile[0].allergies) || ''
      ),
      pregnent: new FormControl(
        this.serviceScript && this.serviceScript.healthprofile[0].pregnency == 0
          ? 'No'
          : this.serviceScript.healthprofile[0].pregnency == 1
            ? 'Yes'
            : ''
      ),

      gender: new FormControl(
        (this.serviceScript && this.serviceScript.orderData.gender) || ''
      ),
      lactation: new FormControl(
        this.serviceScript && this.serviceScript.healthprofile[0].lactation == 0
          ? 'No'
          : this.serviceScript.healthprofile[0].lactation == 1
            ? 'Yes'
            : ''
      ),

      smoking: new FormControl(
        this.serviceScript && this.serviceScript.healthprofile[0].smoking == 0
          ? 'No'
          : this.serviceScript.healthprofile[0].smoking == 1
            ? 'Yes'
            : ''
      ),
      medicalconditions: new FormControl(
        (this.serviceScript && this.serviceScript.healthprofile[0].medicalconditions) || ''
      ),
      weight: new FormControl(
        (this.serviceScript && this.serviceScript.healthprofile[0].weight) || ''
      ),
      email: new FormControl(
        (this.serviceScript && this.serviceScript.orderData.emailaddress) || ''
      ),
      mobilenumber: new FormControl(
        (this.serviceScript && this.serviceScript.orderData.mobilenumber) || ''
      ),
      medicarenumber: new FormControl(
        (this.serviceScript &&
          this.serviceScript.healthprofile[0].medicarenumber &&
          this.serviceScript.healthprofile[0].medicarenumber.slice(0, -1)) ||
        ''
      ),
      heathcarecard: new FormControl(
        (this.serviceScript && this.serviceScript.healthprofile[0].pensionerconcessioncard) ||
        ''
      ),
      safetynet: new FormControl(
        (this.serviceScript && this.serviceScript.healthprofile[0].safetynet) || ''
      ),
      veteranaffairsnumber: new FormControl(
        (this.serviceScript && this.serviceScript.healthprofile[0].veteranaffairsnumber) || ''
      ),
      dateofbirth: new FormControl(
        (this.serviceScript && this.serviceScript.orderData.dateofbirth) || ''
      ),
      descriptionforprocedure: new FormControl(
        (this.serviceScript && this.serviceScript.healthprofile[0].descriptionforprocedure) ||
        ''
      ),
      oncontraception: new FormControl(
        this.serviceScript && this.serviceScript.healthprofile[0].oncontraception == 0
          ? 'No'
          : this.serviceScript.healthprofile[0].oncontraception == 1
            ? 'Yes'
            : ''
      ),

      medicare_exp: new FormControl(
        (this.serviceScript && this.serviceScript.healthprofile[0].medicare_exp) || ''
      ),
      veteranaffairsexp: new FormControl(
        (this.serviceScript && this.serviceScript.healthprofile[0].veteranaffairsexp) || ''
      ),
      statusSelected: new FormControl(
        this.serviceScript && this.serviceScript.orderData.orderstatus
      ),
      refno: new FormControl(
        (this.serviceScript &&
          this.serviceScript.healthprofile[0].medicarenumber &&
          this.serviceScript.healthprofile[0].medicarenumber.slice(-1)) ||
        ''
      ),
    });
  }

  openDialog() {
    const dialogRef = this.dialog.open(DialogItemComponent, {
      data: {
        originalScripts: this.serviceScript.originalscriptslist,
        orderStatusCode: this.serviceScript.orderData.status_code
      },
      width: '90%',
      maxWidth: '900px'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.removeFragment();
      if (result) {
        this.getOrderDetails(this.serviceScript.orderData.orderid);
      }
    });
  }

  open_variants_dialog(type, data) {
    const dialogRef = this.dialog.open(DialogVariantsComponent, {
      data: {
        type: type,
        variants: data,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log(`Dialog result: ${result}`);
    });
  }

  open_med_cert_dialog(data) {
    this.dataOrder.get_med_cert_doctors().subscribe(
      res => {
        // console.log(res);
        const doctors = res.data;

        const dialogRef = this.dialog.open(DialogGenMedCertComponent, {
          data: {
            element: data,
            doctors: doctors,
          },
        });

        dialogRef.afterClosed().subscribe(result => {
          this.getOrderDetails(this.serviceScript.orderData.orderid);
          // console.log(`Dialog result: ${result}`);
        });
      },
      err => {
        this.router.navigate(['../../access-denied']);
      }
    );
  }

  openDialogCancel(x, y) {
    // this.loading = true
    const dialogRef = this.dialog.open(DialogCancelComponent, {
      data: {
        itemCodes: this.items.map(item => item.prescriptionid),
        locationId: this.catalogue.otcCatalogueLocationId,
      },
      width: '44%',
      maxWidth: '800px',
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log(`Dialog result: ${result}`);
      // console.log('results', result)
      result.productsToChange.map(item => {
        if (result.reason === 'Out of Stock') {
          item.Availability = 0;
        } else if (result.reason === 'Do not stock item') {
          item.Status = 0;
        }
      });
      this.loading = true;
      result.productsToChange.forEach(item => {
        this.product.setProductsForPharmacy(item).subscribe(res => { });
      });
      // console.log('item', x, y, result)
      this.dataOrder
        .changeOrderStatus(this.serviceScript.orderData.orderid, x, y, result.reason)
        .subscribe(res => {
          // console.log('response ', res)
          this.validate(res, y);
          this.loading = false;
        });
    });
  }

  menuItemAction(action) {
    switch (action) {
      case 'Edit Address':
        this.openDeliveryDetailsDialog();
        break;
      case 'Print Order':
        this.printOrder();
        break;
      case 'Cancel Order':
        this.preChangeStatus('Cancel');
        break;
      case 'Refund':
        this.initiateRefund();
        break;
      case 'Send Invoice':
        this.sendInvoice();
        break;
      case 'Recall DoorDash':
        this.recallDoorDash();
        break;
      case 'Check Delivery Status':
        this.checkStatus();
        break;
      case 'Print Label':
        this.printLabel();
        break;
      case 'Send Request Email':
        this.resubmitForm();
        break;
      case 'Links':
        this.openLinksDialog();
        break;
      case 'Assign Medication':
        this.openAssignMedicationDialog();
        break;
      case 'Change Pharmacy':
        this.router.navigate([
          '/orders/changepharmacy/',
          this.serviceScript.orderData.locationid,
          this.serviceScript.orderData.addpostcode,
          this.serviceScript.orderData.orderid,
        ]);
        break;
      case OrderActions.CreateFollowUpAppointment:
        this.openCreateFollowUpRequestDialog();
        break;
      default:
        break;
    }
  }

  isTelehealthItem(item) {
    if (
      this.serviceScript.orderData.Source.toLowerCase().includes('telehealth') &&
      this.admin === '0' &&
      (item.prescriptionid?.includes('MMC-T') || item.prescriptionid?.includes('MMB'))
    ) {
      return false;
    }
    return true;
  }

  openCreateFollowUpRequestDialog() {
    this.dialog.open(DialogCreateFollowUpRequestComponent, {
      width: '90%',
      maxWidth: '1300px',
      data: { orderDetail: this.serviceScript, tiles: this.viewItems },
    });
  }

  openLinksDialog() {
    this.dialog.open(LinksComponent, {
      width: '80%',
      maxWidth: '600px',
      data: this.serviceScript.orderData.orderid,
    });
  }

  openAssignMedicationDialog() {
    const diaog = this.dialog.open<
      DialogAssignMedicationComponent,
      any,
      { env: Environment } & AssignableMedicationPayload
    >(DialogAssignMedicationComponent, {
      width: '80%',
      maxWidth: '600px',
    });

    diaog
      .afterClosed()
      .pipe(
        filter(data => !!data),
        tap(() => (this.loading = true)),
        switchMap(data => {
          const { env, ...payload } = data;
          return this.dataOrder.assignMedicationToOrder(
            this.serviceScript.orderData.orderid,
            payload as AssignableMedicationPayloadWithEnv,
            env
          );
        })
      )
      .subscribe(
        res => {
          this.loading = false;
          this._snackBar.openFromComponent(AlertSnackbarComponent, {
            panelClass: ['mm-bg-green'],
            data: {
              msg: res.msg,
              action: 'dismiss',
            },
            verticalPosition: 'top',
            horizontalPosition: 'center',
            duration: 2000,
          });
          this.loading = false;
          this.getOrderDetails(this.serviceScript.orderData.orderid);
        },
        err => {
          let errorMessage = 'An error occurred while assigning medication to order.';
          if (err?.error?.errors) {
            const error = Object.values(err.error.errors)?.[0] as string;
            if (error) {
              errorMessage = error;
            }
          } else if (err?.error?.message) {
            errorMessage = err.error.message;
          } else if (err?.error?.failed_tokens?.length > 0) {
            errorMessage = `The following medications could not be assigned: ${err.error.failed_tokens.join(
              ', '
            )}`;
          }
          this._snackBar.openFromComponent(AlertSnackbarComponent, {
            panelClass: ['mat-bg-warn'],
            data: {
              msg: errorMessage,
              action: 'dismiss',
            },
            verticalPosition: 'top',
            horizontalPosition: 'center',
          });
          this.loading = false;
        }
      );
  }

  openDeliveryDetailsDialog() {
    const dialogRef = this.dialog.open(DialogDeliveryComponent, {
      data: {
        addiDelivInstr: this.a_d_i || '',
        delivInstr: this.d_i || '',
        addrLine1: this.viewItems[3].customerDetails.addressLine1 || '',
        addrLine2: this.viewItems[3].customerDetails.addressLine2 || '',
        suburb: this.viewItems[3].customerDetails.suburb || '',
        state: this.viewItems[3].customerDetails.state || '',
        postcode: this.viewItems[3].customerDetails.postcode || '',
        orderType: this.serviceScript.orderData.ordertype,
        // partners: this.serviceScript.deliveryPartnerNames.map(p => p.partnername),
        // partners: this.serviceScript.deliveryPartnerNames,
        deliveryPartnerId: this.serviceScript.orderData.deliveryPartnerId,
        dpn: this.serviceScript.orderData.deliverypartnername,
        selectedPartner:
          (this.serviceScript.orderData.deliveryPartnerId
            ? this.serviceScript.deliveryPartnerNames.find(
              (partner: DeliveryPartner) =>
                partner.partneroptionid == this.serviceScript.orderData.deliveryPartnerId
            )
            : this.serviceScript.deliveryPartnerNames.find(
              (partner: DeliveryPartner) =>
                partner.partnername === this.serviceScript.orderData.deliverypartnername
            )) ?? DEFAULT_PARTNER,
        pd: this.serviceScript.orderData.userpreffereddate,
        pt: this.serviceScript.orderData.userprefferedtime,
        orderid: this.serviceScript.orderData.orderid,
        userid: this.serviceScript.orderData.myuserid,
        mobile: this.serviceScript.orderData.mobilenumber,
        firstname: this.serviceScript.orderData.fname,
        lat: this.serviceScript.orderData.latitude,
        lng: this.serviceScript.orderData.longitude,
        locationid: this.serviceScript.orderData.locationid,
        status: this.serviceScript.orderData.orderstatus,
        ordertotal: this.serviceScript.orderData.orderTotal,
        deliveryaddressid: this.serviceScript.orderData.deliveryaddressid,
        deliveryFee: this.serviceScript.orderData.deliveryfee,
        servicefee: this.serviceScript.orderData.customerServiceFee,
        scriptcart: this.serviceScript.scriptslist,
      },
      width: '80%',
      maxWidth: '600px',
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this._snackBar.openFromComponent(AlertSnackbarComponent, {
          panelClass: ['mm-bg-green'],
          data: {
            msg: 'Details updated successfully',
            action: 'dismiss',
          },
          verticalPosition: 'top',
          horizontalPosition: 'center',
          duration: 2000,
        });

        //reset data
        this.getOrderDetails(this.serviceScript.orderData.orderid);
      }
    });
  }

  //add items to array of items that have been reduced or removed
  addToItemsEdited(element) {
    let found = this.itemsEdited.find(item => item.prescriptionid == element.prescriptionid);
    if (!found) {
      this.itemsEdited.push(element);
    }
  }
  //add items to array of items that have been increased or price has been edited
  addToItemsEdited1(element) {
    let found = this.itemsEdited1.find(item => item.prescriptionid == element.prescriptionid);
    if (!found) {
      this.itemsEdited1.push(element);
    }
  }

  viewScript(item) {
    if (item.prescriptiontype == PAPER_SCRIPT) {
      window.open(item.itemimage, '_blank');
      return;
    }
    window.open(item.script_url, '_blank');
  }
  Dispense(script) {
    // console.log("script", script)
    // console.log("serviceScript",this.serviceScript)
    this.loading = true;
    const data = {
      locationid: script.locationid ? script.locationid : null,
      barcode: script.barcode ? script.barcode : null,
      mobilenumber: this.serviceScript.orderData.mobilenumber
        ? this.serviceScript.orderData.mobilenumber
        : null,
      issent: script.issent != null ? script.issent : null,
      prescriptionitemid: script.prescriptionitemid ? script.prescriptionitemid : null,
      order_id: script.orderid ? script.orderid : null,
    };

    this.dataOrder.sendToMedViewFlow(data).subscribe(
      res => {
        this._snackBar.openFromComponent(AlertSnackbarComponent, {
          panelClass: ['mm-bg-green'],
          data: {
            msg: res.msg,
            action: 'dismiss',
          },
          verticalPosition: 'top',
          horizontalPosition: 'center',
          duration: 2000,
        });
        this.loading = false;
      },
      err => {
        this._snackBar.openFromComponent(AlertSnackbarComponent, {
          panelClass: ['mat-bg-warn'],
          data: {
            msg: err.error.msg,
            action: 'dismiss',
          },
          verticalPosition: 'top',
          horizontalPosition: 'center',
          duration: 2000,
        });
        this.loading = false;
      }
    );
  }

  getEntitlement(id: number) {
    return Entitlement[id] ?? null;
  }

  printEScript(link) {
    var iframe = document.createElement('iframe');
    var body = document.getElementsByTagName('body')[0];
    body.appendChild(iframe);
    iframe.width = '1000';
    iframe.height = '1800';
    iframe.style.display = 'none';
    iframe.id = 'print-frame';
    const styles = `
    @media print {
      @page { size: A4;  margin: 2mm; }
    }`;

    document.body.appendChild(iframe);
    let contentWindow = iframe.contentWindow;
    contentWindow.document.open();
    contentWindow.document.write(`<html><head><title></title>`);
    contentWindow.document.write(`<style>${styles}</style>`);
    contentWindow.document.write('</head><body >');
    contentWindow.document.write('<div class="parent">');
    contentWindow.document.write(
      '<iframe src="' +
      link +
      '" onload="print();" width="1000" height="1800" frameborder="0">'
    );
    contentWindow.document.write('</div>');
    contentWindow.document.write('</body></html>');
    contentWindow.document.close();
    contentWindow.addEventListener('afterprint', event => {
      document.querySelector('#print-frame').remove();
    });
  }

  printLabel() {
    const data = {
      id: this.serviceScript.orderData.orderid,
    };

    this.dataOrder.getLabels(data).subscribe(
      pdf => {
        const blobUrl = window.URL.createObjectURL(pdf);

        // Create a link element
        const link = document.createElement('a');
        link.setAttribute('style', 'display: none');
        link.href = blobUrl;
        link.download = `${this.serviceScript.orderData.orderid}.pdf`;
        document.body.appendChild(link);
        link.click();

        // Clean up
        window.URL.revokeObjectURL(blobUrl);
        document.body.removeChild(link);
      },
      err => {
        this.alertService.notifyError('Delivery not found')
      }
    );
  }

  resubmitForm(): void {
    if (this.serviceScript.survey_data.length === 0) return;

    if (this.serviceScript.survey_data.length > 1) {
      const resendFormDialog = this.dialog.open(ResendFormDialog, {
        minWidth: '400px',
        width: '40%',
        maxWidth: '500px',
        data: this.serviceScript.survey_data,
      });
      resendFormDialog.afterClosed().subscribe((data: number[]) => {
        this.sendForms(data);
      });
      return;
    }

    this.sendForms([this.serviceScript.survey_data[0].id]);
  }

  sendForms(data: number[]): void {
    data.forEach(formId => {
      this.loading = true;
      this.dataOrder.sendFormToStaff(formId).subscribe(
        response => {
          this._snackBar.openFromComponent(AlertSnackbarComponent, {
            panelClass: ['mm-bg-green'],
            data: {
              msg: 'Form request email sent successfully',
              action: 'dismiss',
            },
            verticalPosition: 'top',
            horizontalPosition: 'center',
            duration: 1300,
          });
          this.loading = false;
        },
        err => {
          this._snackBar.openFromComponent(AlertSnackbarComponent, {
            panelClass: ['mm-bg-warn'],
            data: {
              msg: 'Unable to send request email, please contact support team.',
              action: 'dismiss',
            },
            verticalPosition: 'top',
            horizontalPosition: 'center',
            duration: 1300,
          });
          this.loading = false;
        }
      );
    });
  }

  showNotes() { }
  viewOrder(orderId: number) {
    this.router.navigate(['orders/vieworders/', orderId]);
  }
  doAny() {
    return true;
  }

  matchesTelehealthUrlPattern() {
    const url = this.router.url;
    const urlPattern = /\/telehealth\/(pending|script-issued)\/vieworders\//;
    return url.match(urlPattern);
  }

  viewButtons() {
    if (this.jwtAuth.isPharmacyUser() && this.matchesTelehealthUrlPattern()) {
      return false;
    }
    if (this.orderStatusArr.includes(this.currentStatus)) {
      return true;
    }
  }

  canShowEditDeliveryInstructionsButton() {
    if (this.jwtAuth.isPharmacyUser() && this.matchesTelehealthUrlPattern()) {
      return false;
    }
    return this.serviceScript.orderData.ordertype == 'Delivery' && !this.uberOrder;
  }

  showOnlyProceedButton() {
    return (
      this.jwtAuth.isPharmacyUser() &&
      this.router.url.includes('/telehealth/script-issued/vieworders/') &&
      this.currentStatus === 'Request Issued'
    );
  }

  getTopButtonText1() {
    if (this.orderStatusArr.includes(this.currentStatus)) {
      return 'Print Order';
    }
  }

  private addPageNumbers(doc: jsPDF) {
    const pageCount = doc.internal.pages.length - 1;
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.setFontSize(10);
      doc.setTextColor(100);
      doc.text('Page ' + i + ' of ' + pageCount, doc.internal.pageSize.width / 2, doc.internal.pageSize.height - 10, { align: 'center' });
    }
  }

  // PDF generation
  private generateOrderPDF(): Blob {
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.width;
    const pageHeight = doc.internal.pageSize.height;

    let yOffset = 10; // Start y-coordinate
    const lineGap = 8;
    const detailsYOffset = yOffset;

    // Logo and Order ID block
    const logoWidth = pageWidth / 3;
    let logoHeight = 0;
    
    // try {
    //   const img = new Image();
    //   img.src = this.logo;
    //   const imgProps = doc.getImageProperties(img);
    //   logoHeight = (logoWidth / imgProps.width) * imgProps.height;
    //   doc.addImage(this.logo, 'PNG', 10, yOffset, logoWidth, logoHeight);
    //   console.log('this.logo', this.logo);
    // } catch (error) {
    //   console.error('Error loading logo:', error);
    //   logoHeight = 30; // Set a default height if image fails to load
    // }
  
    // Pharmacy Name and Order ID
    doc.setTextColor("#1e98d5");
    doc.setFontSize(16);
    doc.text(this.serviceScript.orderData.locationname, pageWidth - 10, yOffset + 10, { align: 'right' });
  
    doc.setTextColor(0, 0, 0); // Black color
    doc.setFontSize(12);
    doc.text('#' + this.serviceScript.orderData.uniqueorderid, pageWidth - 10, yOffset + 16, { align: 'right' });
  
    doc.setTextColor(0, 0, 0); // Black color
    doc.setFontSize(10);
    doc.text(this.serviceScript.orderData.orderDate, pageWidth - 10, yOffset + 22, { align: 'right' });
    // Add bottom border
    yOffset += Math.max(logoHeight - 5, 25); // Use the larger of logo height or text height, plus some padding
    doc.setDrawColor(0);
    doc.setLineWidth(2 / 72); // 2px in points
    doc.line(10, yOffset, pageWidth - 10, yOffset);
  
    // Function to set bold style
    const setBold = () => doc.setFont(undefined, 'bold');
    // Function to set normal style
    const setNormal = () => doc.setFont(undefined, 'normal');
  
    // 2nd block: Order Details
    // doc.setFontSize(14);
    // doc.setTextColor("#1e98d5");
    // doc.text('Order Details', 15, yOffset += lineGap);

    // doc.setFontSize(10);
    // doc.setTextColor(0, 0, 0); // Black color
    

    // setBold();
    // doc.text('Date:', 20, yOffset += lineGap);
    // setNormal();
    // doc.text(`${this.serviceScript.orderData.orderDate}`, 70, yOffset);

    // setBold();
    // doc.text('From:', 20, yOffset += lineGap);
    // setNormal();
    // doc.text(`${this.serviceScript.orderData.Source}`, 70, yOffset);

    // if (this.serviceScript.orderData.subscription) {
    //   setBold();
    //   doc.text('Subscription:', 20, yOffset += lineGap);
    //   setNormal();
    //   // Clean the subscription name
    //   const cleanSubscriptionName = this.serviceScript.orderData.subscription.name
    //     .replace(/[^\x20-\x7E]/g, '') // Remove non-printable characters
    //     .trim(); // Remove leading and trailing whitespace
    //   doc.text(cleanSubscriptionName, 70, yOffset);
    // }

    // if (this.serviceScript.orderData.subscription && this.serviceScript.orderData.subscription.cycle_number) {
    //   setBold();
    //   doc.text('Subscription Cycle:', 20, yOffset += lineGap);
    //   setNormal();
    //   doc.text(`${this.serviceScript.orderData.subscription.cycle_number}`, 70, yOffset);
    // }

    // setBold();
    // doc.text('Promo Code:', 20, yOffset += lineGap);
    // setNormal();
    // doc.setTextColor('#FF3D57'); // Red color
    // doc.text(`${this.serviceScript.orderData.promoCode || 'No promotion code applied'}`, 70, yOffset);

    // yOffset += lineGap
    // doc.setDrawColor(0);
    // doc.setLineWidth(2 / 72); // 2px in points
    // doc.line(10, yOffset, pageWidth - 10, yOffset);
  
    // // 3rd block: Pharmacy Details
    // doc.setFontSize(14);
    // doc.setTextColor("#1e98d5");
    // // doc.text(`Pharmacy Details (${this.getPartnerStatus(this.serviceScript.orderData.locationstatus)})`, 15, yOffset += lineGap);
    // doc.text('Pharmacy Details', 15, yOffset += lineGap);

    // doc.setFontSize(10);
    // doc.setTextColor(0, 0, 0); // Black color
    // doc.text(this.serviceScript.orderData.locationname, 20, yOffset += lineGap);
    // if (this.serviceScript.orderData.phone) {
    //   doc.text(this.serviceScript.orderData.phone, 20, yOffset += lineGap);
    // }
    // if (this.serviceScript.orderData.address) {
    //   doc.text(this.serviceScript.orderData.address, 20, yOffset += lineGap);
    // }
    // if (this.serviceScript.orderData.timings) {
    //   doc.text(this.serviceScript.orderData.timings, 20, yOffset += lineGap);
    // }

    // yOffset += lineGap
    // doc.setDrawColor(0);
    // doc.setLineWidth(2 / 72); // 2px in points
    // doc.line(10, yOffset, pageWidth - 10, yOffset);

    // // 4th block: Pharmacy Instructions
    // doc.setFontSize(14);
    // doc.setTextColor("#1e98d5");
    // doc.text('Pharmacy Instructions', 15, yOffset += lineGap);

    // doc.setFontSize(10);
    // doc.setTextColor(0, 0, 0); // Black color
    // doc.text(this.serviceScript.orderData.pickupinstructions || 'No instructions', 20, yOffset += lineGap);
    // setBold();
    // doc.text('Allergies:', 20, yOffset += lineGap);
    // setNormal();
    // doc.setTextColor('#FF3D57'); // Red color
    // doc.text(this.serviceScript.healthprofile[0].allergies || 'No allergies', 70, yOffset);
  
    // yOffset += lineGap
    // doc.setDrawColor(0);
    // doc.setLineWidth(2 / 72); // 2px in points
    // doc.line(10, yOffset, pageWidth - 10, yOffset);
  
    // 5th block: Customer Details
    doc.setFontSize(14);
    doc.setTextColor("#1e98d5");
    doc.text('Customer Details', 15, yOffset += lineGap);

    const lineGapInContent = 5;
    doc.setFontSize(10);
    doc.setTextColor(0, 0, 0); // Black color
    doc.text(`${this.serviceScript.orderData.fname} ${this.serviceScript.orderData.lname}`, 20, yOffset += lineGap);
    doc.text(this.serviceScript.orderData.emailaddress, 20, yOffset += lineGapInContent);
    doc.text(this.serviceScript.orderData.mobilenumber, 20, yOffset += lineGapInContent);
    const address = [
      this.serviceScript.orderData.addr1,
      this.serviceScript.orderData.addr2,
      `${this.serviceScript.orderData.addsuburb}, ${this.serviceScript.orderData.addstate}, ${this.serviceScript.orderData.addpostcode}`
    ].filter(Boolean).join(', ');
    doc.text(address, 20, yOffset += lineGapInContent);

    if (this.serviceScript.orderData.deliverypartnername) {
      setBold();
      doc.text('Type:', 20, yOffset += lineGapInContent);
      setNormal();
      doc.text(this.serviceScript.orderData.deliverypartnername, 70, yOffset);
    }
    if (this.serviceScript.orderData.deliveryinstructions) {
      setBold();
      doc.text('Delivery Instructions:', 20, yOffset += lineGapInContent);
      setNormal();
      const deliveryInstructions = this.serviceScript.orderData.deliveryinstructions;
      doc.text(deliveryInstructions, 70, yOffset);
    }

    yOffset += lineGap
    doc.setDrawColor(0);
    doc.setLineWidth(2 / 72); // 2px in points
    doc.line(10, yOffset, pageWidth - 10, yOffset);

    // 6th block: Order Notes
    // doc.setFontSize(14);
    // doc.setTextColor("#1e98d5");
    // doc.text('Order Notes', 15, yOffset += lineGap);

    // doc.setFontSize(10);
    // doc.setTextColor(0, 0, 0); // Black color
    // doc.text(this.serviceScript.orderData.orderinstructions || 'No order notes', 20, yOffset += lineGap);

    // 7th block: Scripts and Items
    // doc.addPage();
    yOffset += lineGap;
    // Function to create table for both Scripts and Items
    const createTable = (title: string, data: any[], startY: number) => {
      doc.setFontSize(14);
      doc.text(title, 10, startY);
      doc.setFontSize(10);

      const headers = [
        { header: 'Details', dataKey: 'details' },
        { header: 'EAN', dataKey: 'ean' },
        { header: 'Customer Price', dataKey: 'price' },
        { header: 'QTY', dataKey: 'qty' },
        { header: 'Amount', dataKey: 'amount' }
      ];

      const tableData = data.map(item => ({
        details: item.drugname
          .replace(/[^\x20-\x7E]/g, '') // Remove non-printable characters
          .trim(), // Remove leading and trailing whitespace,
        ean: item.EAN || 'N/A',
        price: `$${parseFloat(item.originalprice).toFixed(2)}`,
        qty: item.drugpack,
        amount: `$${this.calculateTotalPrice(item).toFixed(2)}`
      }));

      autoTable(doc, {
        startY: startY + 4,
        head: [headers.map(h => h.header)],
        body: tableData.map(d => headers.map(h => d[h.dataKey])),
        margin: { top: 10, right: 10, bottom: 10, left: 10 },
        styles: { overflow: 'linebreak', cellWidth: 'wrap' },
        columnStyles: {
          0: { cellWidth: 'auto' },
          1: { cellWidth: 40 },
          2: { cellWidth: 40 },
          3: { cellWidth: 30 },
          4: { cellWidth: 40 }
        }
      });

      return (doc as any).lastAutoTable.finalY;
    };

    // Scripts table (if exists)
    if (this.scripts && this.scripts.length > 0) {
      yOffset = createTable('Scripts', this.scripts, yOffset += lineGap);
    }

    // Items table
    if (this.items && this.items.length > 0) {
      yOffset = createTable('Items', this.items, yOffset += lineGap);
    }
  
    // 8th block: Cost Summary
    yOffset = (doc as any).lastAutoTable.finalY + 10;
    doc.setFontSize(12);
    const labelX = pageWidth - 70;
    const valueX = pageWidth - 10;

    const addCostLine = (label: string, value: string, fontSize: number = 12) => {
        doc.setFontSize(fontSize);
        doc.text(label, labelX, yOffset);
        doc.text(value, valueX, yOffset, { align: 'right' });
        yOffset += lineGap;
    };

    addCostLine('Sub Total:', `$${this.calculateSubTotal()}`);
    addCostLine('Delivery Fee:', `$${this.serviceScript.orderData.deliveryfee}`);
    addCostLine('Service Fee:', `$${this.serviceScript.orderData.customerServiceFee}`);
    addCostLine('Order Total:', `$${this.calculateOrderTotal()}`, 14);

    this.addPageNumbers(doc);
  
    return doc.output('blob');
  }

  printOrder() {
    try {
      const pdf = this.generateOrderPDF();
      const blobUrl = window.URL.createObjectURL(pdf);
  
      // Create a link element
      const link = document.createElement('a');
      link.setAttribute('style', 'display: none');
      link.href = blobUrl;
      link.download = `Order_${this.serviceScript.orderData.uniqueorderid}.pdf`;
      document.body.appendChild(link);
      link.click();
  
      // Clean up
      window.URL.revokeObjectURL(blobUrl);
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error generating PDF:', error);
      this.alertService.notifyError('Error generating PDF. Please try again.');
    }
  }

  moreActionsButton() {
    this.showMoreActionsButton = true;
  }

  moreActions() {
    this.showMoreActions = !this.showMoreActions;
    if (this.showMoreActions) {
      // Add click listener to document when menu is opened
      this.documentClickListener = this.closeMenuOnClick.bind(this);
      document.addEventListener('click', this.documentClickListener);
    } else {
      // Remove click listener when menu is closed
      document.removeEventListener('click', this.documentClickListener);
    }
  }

  // Method to close the menu
  private closeMenuOnClick() {
    this.showMoreActions = false;
    document.removeEventListener('click', this.documentClickListener);
  }

  getTopButtonText2() {
    var arr = this.filterCopyArray(['New', 'Rejected']);

    if (this.currentStatus == 'New' || this.currentStatus == 'Rejected') {
      return 'Accept';
    } else if (arr.includes(this.currentStatus)) {
      return this.editshow == 0 ? 'Edit Order' : 'Save';
    }
  }
  getTopButton1Color() {
    var arr = this.filterCopyArray(['Rejected']);
    if (this.editshow == 1) {
      return 'lightgrey';
    } else if (this.currentStatus == 'Rejected') {
      return '#304156';
    } else if (arr.includes(this.currentStatus)) {
      return '#ff7901';
    } else {
      return 'black';
    }
  }

  getTopButton2Color() {
    var arr = this.filterCopyArray(['New', 'Rejected']);

    if (this.currentStatus == 'New' || this.currentStatus == 'Rejected') {
      return '#72cb38';
    } else if (arr.includes(this.currentStatus)) {
      return '#304156';
    } else {
      return 'black';
    }
  }

  getTopButton3Color() {
    var arr = this.filterCopyArray(['New']);
    if (this.editshow == 1) {
      return 'lightgrey';
    } else if (this.currentStatus == 'New') {
      return '#ff3d57';
    } else if (arr.includes(this.currentStatus)) {
      return '#72cb38';
    } else {
      return 'black';
    }
  }

  getBottomButton1Color() {
    var arr = this.filterCopyArray(['New', 'Rejected']);
    if (this.editshow == 1) {
      return 'lightgrey';
    } else if (this.currentStatus == 'New' || this.currentStatus == 'Rejected') {
      return '#72cb38';
    } else if (arr.includes(this.currentStatus)) {
      return '#ff3d57';
    } else {
      return 'black';
    }
  }
  getBottomButton2Color() {
    var arr = this.filterCopyArray(['New']);
    if (this.editshow == 1) {
      return 'lightgrey';
    } else if (this.currentStatus == 'New') {
      return '#ff3d57';
    } else if (arr.includes(this.currentStatus)) {
      return '#72cb38';
    } else {
      return 'black';
    }
  }
  getTopButtonText3() {
    var arr = this.filterCopyArray([
      'New',
      'Awaiting',
      'In Transit',
      'Rejected',
      'Pending Payment',
      'Completed',
      'Cancelled',
    ]);
    if (this.currentStatus == 'New' || this.currentStatus == 'Rejected') {
      return 'Accept';
    } else if (arr.includes(this.currentStatus)) {
      return 'Proceed';
    } else if (this.currentStatus == 'Awaiting Pickup') {
      return 'Collected';
    } else if (this.currentStatus == 'In Transit') {
      return 'Complete';
    }
  }
  showTopButton1() {
    return this.orderStatusArr.includes(this.currentStatus);
  }
  showTopButton2() {
    if (this.isDriverUser) {
      return false;
    }

    if (this.admin === '1') {
      return this.filterCopyArray([
        'Pending Payment',
        'Completed',
        'New',
        'Rejected',
      ]).includes(this.currentStatus);
    }

    return this.filterCopyArray([
      'Pending Payment',
      'Completed',
      'Pending Cancellation',
      'Awaiting Authorisation',
      'New',
      'Rejected',
    ]).includes(this.currentStatus);
  }
  showRejectButton() {
    return this.currentStatus === 'New';
  }

  showTopButton3() {
    if (this.admin === '1') {
      return this.filterCopyArray(['Pending Payment', 'Completed', 'Cancelled']).includes(
        this.currentStatus
      );
    }

    return this.filterCopyArray([
      'Pending Payment',
      'Completed',
      'Cancelled',
      'Pending Cancellation',
      'Awaiting Authorisation',
    ]).includes(this.currentStatus);
  }

  showBottomButton1() {
    if (this.orderStatusArr.includes(this.currentStatus)) {
      return true;
    }
  }
  showBottomButton2() {
    var arr = this.filterCopyArray([
      'Rejected',
      'Pending Payment',
      'Cancelled',
      'Return',
      'Exception',
    ]);
    if (arr.includes(this.currentStatus)) {
      return true;
    } else if (
      this.currentStatus == 'Pending Cancellation' &&
      (this.serviceScript.orderData.paymentstatus == 1 ||
        this.serviceScript.orderData.paymentstatus == 5)
    ) {
      return true;
    }
  }
  showBottomButton3() {
    const partner = this.serviceScript.orderData.deliverypartnername;
    if (this.admin === '1') {
      if (partner === 'DoorDash') {
        if (
          this.currentStatus === 'Process' ||
          this.currentStatus === 'Awaiting Delivery' ||
          this.currentStatus === 'In Transit'
        ) {
          return true;
        }
      }
      if (partner === 'Uber Eats') {
        if (
          this.currentStatus === 'Awaiting Delivery' ||
          this.currentStatus === 'In Transit'
        ) {
          return true;
        }
      }
    }
    return false;
  }

  showBottomButton4() {
    return (
      this.currentStatus == 'Process' ||
      this.currentStatus == 'In Transit' ||
      this.currentStatus == 'Awaiting Delivery' ||
      this.currentStatus == 'Completed' ||
      this.currentStatus == 'Pending Cancellation'
    );
  }

  showAddPaymentMethodButton() {
    return (this.currentStatus === OrderStatusEnum.PendingPayment);
  }

  getBottomButtonText1() {
    var arr = this.filterCopyArray([
      'New',
      'Rejected',
      'Completed',
      'Cancelled',
      'Exception',
      'Return',
    ]);
    if (this.currentStatus == 'New' || this.currentStatus == 'Rejected') {
      return 'Accept';
    } else if (arr.includes(this.currentStatus)) {
      return 'Cancel';
    }
  }

  getBottomButtonText2() {
    if (this.currentStatus == 'New') {
      return 'Reject';
    } else if (
      this.currentStatus == 'Process' ||
      this.currentStatus == 'Waiting For Review' ||
      this.currentStatus == 'Awaiting Authorisation'
    ) {
      return 'Proceed';
    } else if (
      this.currentStatus == 'Awaiting Delivery' ||
      this.currentStatus == 'Awaiting Pickup'
    ) {
      return 'Collected';
    } else if (this.currentStatus == 'In Transit') {
      return 'Complete';
    }
  }
  getBottomButtonText3() {
    const partner = this.serviceScript.orderData.deliverypartnername;
    if (partner === 'DoorDash') {
      return 'Recall DoorDash';
    } else if (partner === 'Uber Eats') {
      return 'Check Uber Status';
    }
  }
  doTextareaValueChange(ev) {
    try {
      this.textareaValue = ev.target.value;
      // console.log(this.textareaValue)
    } catch (e) {
      // console.info('could not set textarea-value');
    }
  }

  getPartnerStatus(status: number | string): string {
    return LOCATION_STATUS_MAP[status] ? LOCATION_STATUS_MAP[status].substr(0, 1) : 'U';
  }

  //copy EAN
  getUID(type, uid) {
    if (uid) {
      navigator.clipboard.writeText(uid);
      this._snackBar.openFromComponent(AlertSnackbarComponent, {
        panelClass: ['mm-bg-green'],
        data: {
          msg: `${type} Copied to Clipboard`,
          action: 'dismiss',
        },
        verticalPosition: 'top',
        horizontalPosition: 'center',
        duration: 1300,
      });
    } else {
      this._snackBar.openFromComponent(AlertSnackbarComponent, {
        panelClass: ['mm-bg-warn'],
        data: {
          msg: `No EAN Available`,
          action: 'dismiss',
        },
        verticalPosition: 'top',
        horizontalPosition: 'center',
        duration: 1300,
      });
    }
    return;

    // this._snackBar.open(`${type} Copied to Clipboard`, '', {
    //   horizontalPosition: 'center',
    //   verticalPosition: 'top',
    //   duration: 1300
    // });
  }

  getCopyHint() {
    return 'Copy EAN';
  }

  showZeroPriceDialog() {
    const zeroPriceDialog = this.dialog.open(DialogZeroPriceComponent, {
      minWidth: '400px',
      width: '20%',
      maxWidth: '800px',
    });

    return new Promise((resolve, reject) => {
      zeroPriceDialog.afterClosed().subscribe(res => {
        if (res.status === 'FAIL') {
          reject();
          return;
        }
        resolve(true);
      });
    });
  }

  get CanShowRepeatOrderButton() {
    this.reorderScripts = this.scripts.filter(item => item.repeats > 0);
    return this.reorderScripts.length > 0;
  }

  showReorderScriptDialog(reorderScripts, isOrderCompleted = false) {
    this._snackBar.openFromComponent(AlertSnackbarComponent, {
      panelClass: ['mm-bg-warn'],
      data: {
        msg: `Authenticating User`,
        action: 'dismiss',
      },
      verticalPosition: 'top',
      horizontalPosition: 'center',
      duration: 2000,
    });
    this.jwtAuth.login(this.serviceScript.orderData.emailaddress, 2).subscribe(
      res => {
        const token = res['success'].token;
        this._snackBar.openFromComponent(AlertSnackbarComponent, {
          panelClass: ['mm-bg-green'],
          data: {
            msg: `User Authenticated`,
            action: 'dismiss',
          },
          verticalPosition: 'top',
          horizontalPosition: 'center',
          duration: 2000,
        });

        this.dialog.open(DialogReorderScriptsComponent, {
          minWidth: '400px',
          width: '20%',
          maxWidth: '800px',
          data: {
            scripts: reorderScripts,
            token,
            isOrderCompleted,
          },
        });
      },
      err => {
        this._snackBar.openFromComponent(AlertSnackbarComponent, {
          panelClass: ['mm-bg-pink'],
          data: {
            msg: `${err.error.message}. Creating new user...`,
            action: 'dismiss',
          },
          verticalPosition: 'top',
          horizontalPosition: 'center',
          duration: 2000,
        });
      }
    );
  }

  preChangeStatus(toStatus) {
    this.loading = true;
    const currentStatus = this.currentStatus;
    const balance = parseInt(this.serviceScript.orderData.orderBalance);
    if (
      currentStatus == 'Awaiting Authorisation' &&
      balance > 1 &&
      this.admin === '1' &&
      toStatus != 'Cancel' &&
      this.serviceScript.orderData.Source != 'femma'
    ) {
      this.confirmManualPayment();
      this.loading = false;
      return;
    } else if (
      currentStatus === 'Pharmacy To Review' &&
      toStatus === 'Clinic To Review' &&
      this.admin === '0'
    ) {
      this.showTelehealthPaymentDialog()
        .then(() => {
          this.changeStatus(toStatus);
        })
        .catch(() => {
          this.loading = false;
        });
      return;
    } else if (
      currentStatus === 'Request Issued' &&
      this.serviceScript.orderData.order_class === 'Telehealth In Store'
    ) {
      toStatus = 'Completed';
    }
    this.changeStatus(toStatus);
  }

  changeStatus(toStatus: string) {
    this.loading = true;
    const currentStatus = this.currentStatus;
    if (toStatus === 'Reject') {
      toStatus = 'Rejected';
      this.loading = false;
      this.confirmCancelOrder(currentStatus, toStatus, true);
      return;
    } else if (toStatus === 'Cancel' && this.admin === '1') {
      toStatus = 'Cancelled';
      this.loading = false;
      this.confirmCancelOrder(currentStatus, toStatus);
      return;
    } else if (toStatus === 'Cancel' && this.admin === '0') {
      toStatus = 'Pending Cancellation';
      this.loading = false;
      this.confirmCancelOrder(currentStatus, toStatus);
      return;
    } else if (currentStatus === 'Waiting For Review') {
      let show_dialog = 0;
      this.scripts.forEach(item => {
        if (item.originalprice == 0) {
          show_dialog = 1;
        }
      });
      this.items.forEach(item => {
        if (item.originalprice == 0) {
          show_dialog = 1;
        }
      });

      if (show_dialog == 1) {
        this.showZeroPriceDialog()
          .then(() => {
            this.request_change(currentStatus, toStatus);
          })
          .catch(() => {
            this.loading = false;
          });

        return;
      }
    }

    if (
      this.jwtAuth.isPharmacyUser() &&
      toStatus === 'Awaiting Delivery' &&
      this.serviceScript.orderData.deliverypartnername === 'Pharmacy Delivery'
    ) {
      this.showPharmacyDeliveryDialog(currentStatus, toStatus);
    } else {
      this.request_change(currentStatus, toStatus);
    }
  }

  request_change(currentStatus, toStatus) {
    this.dataOrder
      ._changeOrderStatus(this.serviceScript.orderData.orderid, currentStatus, toStatus, '')
      .subscribe(
        res => {
          this._snackBar.openFromComponent(AlertSnackbarComponent, {
            panelClass: ['mm-bg-green'],
            data: {
              msg: res.msg,
              action: 'dismiss',
            },
            verticalPosition: 'top',
            horizontalPosition: 'center',
            duration: 2000,
          });
          this.getOrderDetails(this.serviceScript.orderData.orderid);
          this.loading = false;
          const reorderScripts = this.scripts.filter(item => item.repeats > 0);
          console.log(reorderScripts);
          if (
            reorderScripts.length > 0 &&
            (toStatus === 'Awaiting Delivery' || toStatus === 'Awaiting Pickup')
          ) {
            this.showReorderScriptDialog(reorderScripts);
            return;
          }
        },
        err => {
          this._snackBar.openFromComponent(AlertSnackbarComponent, {
            panelClass: ['mat-bg-warn'],
            data: {
              msg: err.error.msg || 'An unknown error occurred.',
              action: 'dismiss',
            },
            verticalPosition: 'top',
            horizontalPosition: 'center',
            duration: 2000,
          });
          this.loading = false;
        }
      );
  }

  showTelehealthPaymentDialog() {
    const dialogRef = this.dialog.open(DialogTelehealthPaymentComponent, {
      minWidth: '400px',
      width: '20%',
      maxWidth: '800px',
      data: {
        requestPrice: this.serviceScript.orderData.script_fee,
      },
    });

    return new Promise((resolve, reject) => {
      dialogRef.afterClosed().subscribe(res => {
        if (res.status === 'FAIL') {
          reject();
          return;
        }
        resolve(true);
      });
    });
  }

  cancelItem() {
    this.displayedColumns = ['Image', 'ItemDetails', 'UnitPrice', 'QTY', 'Amount'];
    this.items = JSON.parse(JSON.stringify(this.items1));
    this.scripts = JSON.parse(JSON.stringify(this.scripts1));
    this.newDeliveryFee = this.serviceScript.orderData.deliveryfee;
    this.newServiceFee = this.serviceScript.orderData.customerServiceFee;
    this.editshow = 0;
    this.addItems = 0;
  }

  saveItem() {
    const otcSubtotal = this.items.reduce(
      (amount, item) => amount + this.calculateTotalPrice(item),
      0
    );
    const scriptSubtotal = this.scripts.reduce(
      (amount, item) => amount + this.calculateTotalPrice(item),
      0
    );
    const newSubtotal = otcSubtotal + scriptSubtotal;
    const newTotal =
      Math.abs(parseFloat(this.newDeliveryFee)) +
      Math.abs(parseFloat(this.newServiceFee)) +
      newSubtotal;
    const oldTotal = parseFloat(this.serviceScript.orderData.orderTotal);
    const orderPaidAmount = parseFloat(this.serviceScript.orderData.orderPaidAmount) || 0;
    const orderBalance = parseFloat(this.serviceScript.orderData.orderBalance) || 0;

    //update originalprice key to be sent in api correctly
    this.items.forEach(item => {
      item.originalprice = this.calculateCustomerPrice(item);
    });
    this.scripts.forEach(item => {
      item.originalprice = this.calculateCustomerPrice(item);
    });

    const continueData = {
      newSubtotal: Math.round(newSubtotal * 100) / 100,
      newTotal: Math.round(newTotal * 100) / 100,
      oldTotal: Math.round(oldTotal * 100) / 100,
      newDeliveryFee: Math.abs(parseFloat(this.newDeliveryFee)),
      newServiceFee: Math.abs(parseFloat(this.newServiceFee)),
      orderPaidAmount: orderPaidAmount,
      orderBalance: orderBalance,
    };
    // console.log(continueData);
    //TODO see if this code is now redundant after adding the above
    // const reducedPriceItems = this.serviceScript.scriptslist.filter(item => parseFloat(item.originalprice) < parseFloat(item.initialItemPrice))

    if (continueData.newTotal != continueData.oldTotal) {
      let amountDifference = Math.abs(continueData.newTotal - continueData.oldTotal);
      let message = '';
      if (continueData.newTotal > continueData.oldTotal) {
        amountDifference = amountDifference + continueData.orderBalance;
        message = `An additional payment request will be sent to the customer for the amount of <b>$${amountDifference.toFixed(
          2
        )}</b>. Would you like to proceed?`;
      } else {
        if (amountDifference <= continueData.orderBalance) {
          message = `An amount of <b>$${amountDifference.toFixed(
            2
          )}</b> will be deducted from the <b>balance owing</b>. Would you like to proceed?`;
        } else {
          if (continueData.newTotal < continueData.oldTotal) {
            if (continueData.orderBalance >= 0) {
              amountDifference = amountDifference - continueData.orderBalance;
            }
            message = `You will be refunding a total of <b>$${amountDifference.toFixed(
              2
            )}</b> to the customer. Would you like to proceed?`;
          }
        }
      }
      const dialogRef = this.dialog.open(ChangeInPriceDialog, {
        minWidth: '400px',
        width: '20%',
        maxWidth: '800px',
        data: {
          ...continueData,
          message: message,
          refundConsent: true,
        },
        disableClose: true,
      });

      dialogRef.afterClosed().subscribe(res => {
        if (res) {
          this.continueSaveItem(res);
        } else {
          this.items = this.items1;
          this.newDeliveryFee = this.serviceScript.orderData.deliveryfee || '0.00';
          this.newServiceFee = this.serviceScript.orderData.customerServiceFee || '0.00';
        }
      });
    } else {
      this.continueSaveItem(continueData);
    }
  }

  continueSaveItem(data) {
    this.loading = true;
    this.displayedColumns = ['Image', 'ItemDetails', 'UnitPrice', 'QTY', 'Amount'];

    this.editshow = 0;
    this.addItems = 0;
    const bodyData = {
      myuserid: this.serviceScript.orderData.myuserid,
      orderid: this.serviceScript.orderData.orderid,
      editOrders: [
        {
          scriptcart: [...this.scripts, ...this.items],
          ordertotal: data.newTotal,
          deliveryfee: data.newDeliveryFee,
          servicefee: data.newServiceFee,
          refundConsent: data.refundConsent ? 1 : 0,
          sendEmail: data.sendEmail == true ? 1 : 0,
        },
      ],
    };
    if (data.manualRefund == 1) {
      bodyData['manualRefund'] = data.manualRefund;
      bodyData['stripeCharge'] = data.stripeCharge;
    }

    this.subtotal = data.newSubtotal.toFixed(2);
    this.serviceScript.orderData.deliveryfee = data.newDeliveryFee.toFixed(2);
    this.serviceScript.orderData.customerServiceFee = data.newServiceFee.toFixed(2);
    this.serviceScript.orderData.orderTotal = data.newTotal.toFixed(2);
    this.newDeliveryFee = this.serviceScript.orderData.deliveryfee;
    this.newServiceFee = this.serviceScript.orderData.customerServiceFee;

    this.dataOrder._editOrder(bodyData).subscribe(
      res => {
        if (res.status == 200) {
          this.serviceScript.orderData.orderTotal = data.newTotal.toFixed(2);
          this.serviceScript.orderData.deliveryfee = data.newDeliveryFee.toFixed(2);
          this.serviceScript.orderData.customerServiceFee = data.newServiceFee.toFixed(2);
          this._snackBar.openFromComponent(AlertSnackbarComponent, {
            panelClass: ['mm-bg-green'],
            data: {
              msg: 'Successfully Updated',
              action: 'dismiss',
            },
            verticalPosition: 'top',
            horizontalPosition: 'center',
            duration: 2000,
          });
          this.getOrderDetails(this.serviceScript.orderData.orderid);
          this.itemsEdited1 = [];
          this.itemsEdited = [];
          this.loading = false;
        } else {
          this._snackBar.openFromComponent(AlertSnackbarComponent, {
            panelClass: ['mat-bg-warn'],
            data: {
              msg: 'Something went wrong',
              action: 'dismiss',
            },
            verticalPosition: 'top',
            horizontalPosition: 'center',
            duration: 2000,
          });
          this.itemsEdited1 = [];
          this.itemsEdited = [];
          this.loading = false;
        }
      },
      err => {
        this._snackBar.openFromComponent(AlertSnackbarComponent, {
          panelClass: ['mat-bg-warn'],
          data: {
            msg: 'Something went wrong',
            action: 'dismiss',
          },
          verticalPosition: 'top',
          horizontalPosition: 'center',
          duration: 2000,
        });
        this.itemsEdited1 = [];
        this.itemsEdited = [];
        this.loading = false;
      }
    );
  }

  //opens dialog for changing stock availability
  openStockInfoDialog() {
    if (
      this.itemsEdited.length === 0 ||
      this.newServiceFee ||
      this.newDeliveryFee ||
      this.catalogue.otcCatalogueLocationId === 0
    ) {
      this.saveItem();
      return;
    }

    const locationid = this.itemsEdited[0].locationid;
    const status = 1;
    const itemCodes = this.itemsEdited.map(item => item.prescriptionid).join(',');
    this.product.getSpecificCatalogueProducts(locationid, status, itemCodes).subscribe(
      res => {
        if (res.length === 0) {
          this.editshow = 0;
          this.saveItem();
          return;
        }
        const dialogRef = this.dialog.open(DialogStockComponent, {
          width: '50%',
          maxWidth: '800px',
          data: res,
          disableClose: true,
          autoFocus: false,
        });

        dialogRef.afterClosed().subscribe(res => {
          this.loading = true;
          res.forEach(item => {
            this.product.setProductsForPharmacy(item).subscribe(
              res => {
                this.loading = false;
                this.saveItem();
              },
              err => {
                // console.log(err)
                this.loading = false;
                this.showErrorSnackBar();
              }
            );
          });
        });
      },
      err => {
        // console.log(err)
        this.showErrorSnackBar();
      }
    );
  }

  showErrorSnackBar() {
    const snack = this._snackBar.openFromComponent(AlertSnackbarComponent, {
      panelClass: ['mat-bg-warn'],
      data: {
        msg: 'There was an error whilst updating this item',
        action: 'dismiss',
      },
      verticalPosition: 'top',
      horizontalPosition: 'center',
      duration: 2000,
    });
    snack.afterDismissed().subscribe(res => {
      this.itemsEdited = [];
    });
  }

  editOrder() {
    this.displayedColumns = [
      'Image',
      'ItemDetails',
      'PharmacyUnitPrice',
      'UnitPrice',
      'QTY',
      'Amount',
      'Action',
    ];

    try {
      const surveyData: SurveyData['survey_data'] = JSON.parse(
        this.serviceScript.survey_data?.[0]?.survey_data
      );
      const surveyDataObj = surveyData?.survey_data;
      if (!this.dataOrder.isTelehealthReferralOrder(surveyDataObj)) {
        this.scriptColumns = this.displayedColumns;
      }
    } catch (error) {
      this.scriptColumns = this.displayedColumns;
      // console.error('Error parsing survey data:', error);
    }
    // if(this.serviceScript.dataOrder.deliverypartnername==="Uber Eats"){
    //   return;
    // }
    this.editshow = 1;
    this.itemEditTable.nativeElement.scrollIntoView({ behavior: 'smooth' });
    this.items1 = JSON.parse(JSON.stringify(this.items));
    this.scripts1 = JSON.parse(JSON.stringify(this.scripts));
  }

  proceed() {
    const toStatus = this.getNextStatus();
    this.preChangeStatus(toStatus);
  }

  getProceedAction() {
    return this.getNextStatus();
  }

  getNextStatus() {
    const currentStatus = this.currentStatus;
    const orderType = this.serviceScript.orderData.ordertype;
    const balance = parseFloat(this.serviceScript.orderData.orderBalance);
    const source = this.serviceScript.orderData.Source;
    const orderClass = this.serviceScript.orderData.order_class;
    const paymentStatus = this.serviceScript.orderData.paymentstatus;

    if (currentStatus === OrderStatusEnum.Process) {
      if (orderType === 'Delivery') {
        return this.statusOptions.Process.next['Delivery'];
      } else if (orderType === 'Pickup' || orderType === 'Pick Up') {
        return this.statusOptions.Process.next['Pickup'];
      }
    } else if (currentStatus === OrderStatusEnum.New) {
      if (balance > 0) {
        return orderType === 'Pick Up' || orderType === 'Pickup'
          ? this.statusOptions.New.next['Paid']
          : this.statusOptions.New.next['Unpaid'];
      } else {
        return this.statusOptions.New.next['Paid'];
      }
    } else if (currentStatus === OrderStatusEnum.RequestIssued && orderClass === 'Telehealth In Store') {
      return OrderStatusEnum.Completed;
    } else if (source.endsWith('Follow-Up') || paymentStatus === PaymentStatusEnum.TELEHEALTH_BULK_BILLED) {
      if (currentStatus === OrderStatusEnum.ClinicToAction) {
        return OrderStatusEnum.PendingBatchProcessing;
      } else if (currentStatus === OrderStatusEnum.PendingBatchProcessing) {
        return OrderStatusEnum.Completed;
      }
    }

    return this.statusOptions[currentStatus].next;
  }


  showPharmacyDeliveryDialog(currentStatus: string, toStatus: string) {
    const dialog = this.dialog.open(DialogConfirmationComponent, {
      minWidth: '400px',
      width: '20%',
      maxWidth: '800px',
      disableClose: true,
      data: {
        headerText: 'Delivery Option Confirmation',
        message:
          "This order is scheduled for Pharmacy Delivery. Please notify your own delivery driver. If you want to choose another delivery option, please choose 'Cancel' and then 'Edit Delivery Instructions' to change the delivery option.",
      },
    });
    dialog.afterClosed().subscribe(result => {
      if (result) {
        this.request_change(currentStatus, toStatus);
      } else {
        this.loading = false;
      }
    });
  }

  openMarkupDialog() {
    const dialogRef = this.dialog.open(MarkupDialog, {
      data: {
        msg: '',
      },
      width: '400px',
      maxWidth: '400px',
    });
  }

  openMyPriceDialog() {
    const dialogRef = this.dialog.open(PharmacyUnitPriceDialog, {
      data: {
        msg: '',
      },
      width: '400px',
      maxWidth: '400px',
    });
  }

  // openStripeChargeDialog() {
  //   const dialogRef = this.dialog.open(StripeChargeDialog, {
  //     data: {
  //       msg: ''
  //     },
  //     width: '400px',
  //     maxWidth: '400px'
  //   });
  // }

  calculateCustomerPrice(element) {
    const pharmacyUnitPrice = element.pharmacyUnitPrice ? element.pharmacyUnitPrice : '0.00';
    const markupAmount = element.PlatformMarkupAmount
      ? parseFloat(element.PlatformMarkupAmount)
      : 0;
    const priceIncludingMarkup = parseFloat(pharmacyUnitPrice) + markupAmount;
    return Math.abs(priceIncludingMarkup).toFixed(2);
  }

  calculateTotalPrice(element) {
    return parseFloat(this.calculateCustomerPrice(element)) * parseFloat(element.drugpack);
  }

  calculateSubTotal() {
    const otcSubtotal = this.items.reduce(
      (amount, item) => amount + this.calculateTotalPrice(item),
      0
    );
    const scriptSubtotal = this.scripts.reduce(
      (amount, item) => amount + this.calculateTotalPrice(item),
      0
    );
    const newSubtotal = otcSubtotal + scriptSubtotal;
    return newSubtotal.toFixed(2);
  }

  calculateOrderTotal() {
    const otcSubtotal = this.items.reduce(
      (amount, item) => amount + this.calculateTotalPrice(item),
      0
    );
    const scriptSubtotal = this.scripts.reduce(
      (amount, item) => amount + this.calculateTotalPrice(item),
      0
    );
    const newSubtotal = otcSubtotal + scriptSubtotal;
    const newTotal =
      Math.abs(parseFloat(this.newDeliveryFee)) +
      Math.abs(parseFloat(this.newServiceFee)) +
      newSubtotal;
    return newTotal.toFixed(2);
  }

  recallDoorDash() {
    this.loading = true;
    this.doorDashService.recallDoordash(this.serviceScript.orderData.orderid).subscribe(
      res => {
        this._snackBar.openFromComponent(AlertSnackbarComponent, {
          panelClass: ['mm-bg-green'],
          data: {
            msg: res.msg,
            action: 'dismiss',
          },
          verticalPosition: 'top',
          horizontalPosition: 'center',
          duration: 2000,
        });
        this.loading = false;
      },
      err => {
        // console.log(err)
        this._snackBar.openFromComponent(AlertSnackbarComponent, {
          panelClass: ['mat-bg-warn'],
          data: {
            msg: err.error ? err.error.msg : 'Unable to recall DoorDash',
            action: 'dismiss',
          },
          verticalPosition: 'top',
          horizontalPosition: 'center',
          duration: 2000,
        });
        this.loading = false;
      }
    );
  }

  checkStatus() {
    this.loading = true;
    this.uberService.checkUberStatus(this.serviceScript.orderData.orderid).subscribe(
      res => {
        this._snackBar.openFromComponent(AlertSnackbarComponent, {
          panelClass: ['mat-bg-green'],
          data: {
            msg: res.message,
            action: 'dismiss',
          },
          verticalPosition: 'top',
          horizontalPosition: 'center',
          duration: 2000,
        });
        this.loading = false;
      },
      err => {
        this._snackBar.openFromComponent(AlertSnackbarComponent, {
          panelClass: ['mat-bg-warn'],
          data: {
            msg: 'Unable to find status',
            action: 'dismiss',
          },
          verticalPosition: 'top',
          horizontalPosition: 'center',
          duration: 2000,
        });
        this.loading = false;
      }
    );
  }

  initiateRefund() {
    const dialogRef = this.dialog.open(DialogRefundComponent, {
      data: {
        order_total: this.serviceScript.orderData.orderTotal,
        order_balance: this.serviceScript.orderData.orderBalance,
        order_paid_amount: this.serviceScript.orderData.orderPaidAmount,
        paid_online: this.serviceScript.orderData.paid_online,
        paid_in_store: this.serviceScript.orderData.paid_in_store,
        orderid: this.serviceScript.orderData.orderid,
      },
      width: '400px',
      maxWidth: '400px',
    });
    dialogRef.afterClosed().subscribe(result => {
      //if result exists or is not null change the status
      // console.log(`Dialog result: ${result}`);
      if (result == 1) {
        this.dialog.closeAll();
        //this.viewItems[0].status = result.newStatus;
      }
    });
  }

  confirmManualPayment() {
    const dialogRef = this.dialog.open(DialogManualPaymentComponent, {
      data: {
        balanceOwing: this.serviceScript.orderData.orderBalance,
        orderid: this.serviceScript.orderData.orderid,
      },
      width: '400px',
      maxWidth: '400px',
    });
    dialogRef.afterClosed().subscribe(result => {
      //if result exists or is not null change the status
      // console.log(`Dialog result: ${result}`);
      this.loading = false;
      if (result == 1) {
        this.dialog.closeAll();
        // console.log(result)
        this.getOrderDetails(this.serviceScript.orderData.orderid);
        // this.changeStatus(this.statusOptions[this.currentStatus].next)
      }
    });
  }

  confirmCancelOrder(currentStatus: string, toStatus: string, isRejectOrder = false) {
    const dialogRef = this.dialog.open(DialogCancelOrderComponent, {
      data: {
        refundAmount: this.serviceScript.orderData.orderPaidAmount,
        orderid: this.serviceScript.orderData.orderid,
        currentStatus,
        toStatus,
        isRejectOrder,
        headerText: isRejectOrder ? 'Reject Order' : null,
      },
      width: '400px',
      maxWidth: '400px',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 1) {
        this.dialog.closeAll();
        this.getOrderDetails(this.serviceScript.orderData.orderid);
      }
    });
  }

  validate(res: any, y: string) {
    // console.log('checking response', res)
    if (res.status == 200) {
      this.currentStatus = y;
      this.openSnackBar('Updated Sucessfully', 'CLOSE');
    } else if (res.status == 400) {
      this.openSnackBar('Please choose valid status', 'CLOSE');
      // this.viewItems[1].status = y
    } else if (res.status == 404) {
      this.openSnackBar('Only admin can change the status to Pending Cancellation', 'CLOSE');
      // this.viewItems[1].status = y
    } else {
      this.openSnackBar('Something went wrong', 'CLOSE');
    }
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 3000,
    });
  }

  cancelUpdateOrderNotes(type) {
    if (type === 'pharmacyOrderNotes') {
      // this.pharmacyOrderNotes.setValue(this.serviceScript.orderData.pharmacyOrderNotes)
      this.showConfirmPharmacyNotes = false;
    } else if (type === 'adminNotes') {
      this.adminNotes.setValue(this.serviceScript.orderData.medmateOrderNotes);
      this.showConfirmAdminNotes = false;
    } else if (type === 'currentOwner') {
      this.currentOwner.setValue(this.serviceScript.orderData.currentOwner);
      this.showConfirmCurrentOwner = false;
    }
  }

  _updateOrderNotes(type) {
    const data = {
      myuserid: this.serviceScript.orderData.myuserid,
      orderid: this.serviceScript.orderData.orderid,
      updateNotes: {},
    };
    if (type === 'pharmacyOrderNotes') {
      data['updateNotes']['ppn'] = this.pharmacyOrderNotes.value;
      this.showConfirmPharmacyNotes = true;
      this.pharmacyOrderNotes.setValue(this.pharmacyOrderNotes.value);
    } else if (type === 'adminNotes') {
      data['updateNotes']['apn'] = this.adminNotes.value;
      this.showConfirmAdminNotes = true;
      this.adminNotes.setValue(this.adminNotes.value);
    } else if (type === 'currentOwner') {
      data['updateNotes']['currentOwner'] = this.currentOwner.value;
      this.showConfirmCurrentOwner = true;
      this.currentOwner.setValue(this.currentOwner.value);
    }

    this.dataOrder._editOrder(data).subscribe(
      res => { },
      err => {
        this.openSnackBar('Unable to update admin notes', 'CLOSE');
        if (type === 'pharmacyOrderNotes') {
          this.pharmacyOrderNotes.setValue(this.serviceScript.orderData.pharmacyOrderNotes);
          // this.showConfirmPharmacyNotes = false
        } else if (type === 'adminNotes') {
          this.adminNotes.setValue(this.serviceScript.orderData.medmateOrderNotes);
        }
      }
    );
  }

  onItemDataChangeQty(key, sign) {
    let found = this.items.find(item => item.orderbasketid == key);
    if (sign === '+') {
      found.drugpack++;
    } else if (sign === '-') {
      found.drugpack--;
    }
  }

  deleteItem(id) {
    this.items = this.items.filter(val => val.orderbasketid != id);
  }
  deleteScript(id) {
    this.scripts = this.scripts.filter(val => val.orderbasketid != id);
  }

  onClickAddItem(event) {
    const selectedItem = event.option.value;
    let newItem: any = {
      drugname: null,
      originalprice: 0,
      drugquantity: 1,
      itemimage: null,
      prescriptionid: null,
      price: null,
      drugpack: 1,
      isscript: 0,
      PlatformMarkupAmount: '1.50',
      pharmacyUnitPrice: '0.00',
      isgeneric: null,
      prescriptiontype: null,
      cardtype: null,
    };

    if (typeof selectedItem === 'string') {
      newItem.drugname = selectedItem;
      newItem.prescriptionid = 'MMB-O000000000';
    } else {
      const platformMarkupAmount = selectedItem.PMA || '0.00';
      const pharmacyUnitPrice = (
        parseFloat(selectedItem.price) - parseFloat(platformMarkupAmount)
      ).toFixed(2);
      newItem.drugname = selectedItem.drug;
      newItem.drugquantity = 1;
      newItem.itemimage = selectedItem.PhotoID_s3;
      newItem.prescriptionid = selectedItem.drugcode;
      newItem.price = selectedItem.price;
      newItem.drugpack = 1;
      newItem.isscript = 0;
      newItem.PlatformMarkupAmount = selectedItem.PMA || '0.00';
      newItem.pharmacyUnitPrice = pharmacyUnitPrice;
      newItem.originalprice = selectedItem.price;
    }
    newItem.orderbasketid = 'mm' + this.itemsEdited.length++;
    this.otcItemSearch.setValue('');
    this.items = [...this.items, newItem];
  }

  getOptionText(option) {
    return option.drug || option;
  }

  //go to order action for order in customer order history tab
  goToOrder(orderId: number) {
    this.router.navigate(['orders/vieworders/', orderId]);
    this.tabIndex = 0;
  }

  sendInvoice() {
    this.invoiceService.generateInvoice(this.serviceScript.orderData.uniqueorderid).subscribe(
      res => {
        this._snackBar.openFromComponent(AlertSnackbarComponent, {
          panelClass: ['mm-bg-green'],
          data: {
            msg: res.Success,
            action: 'dismiss',
          },
          verticalPosition: 'top',
          horizontalPosition: 'center',
          duration: 2000,
        });
        // console.log(res)
      },
      err => {
        this._snackBar.openFromComponent(AlertSnackbarComponent, {
          panelClass: ['mat-bg-warn'],
          data: {
            msg: err.error.Error,
            action: 'dismiss',
          },
          verticalPosition: 'top',
          horizontalPosition: 'center',
          duration: 2000,
        });
      }
    );
  }

  showAddNoteDialog(notes: string) {
    const userRole = this.getUserChatRole();

    const dialogRef = this.dialog.open(AddNoteDialog, {
      width: '400px',
      maxWidth: '400px',
      data: {
        existingNotes: notes,
        role: userRole,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.pharmacyOrderNotes.setValue(result);
        this._updateOrderNotes('pharmacyOrderNotes');
      }
    });
  }

  getUserChatRole(): string {
    return USER_CHAT_ROLE_MAP[this.jwtAuth.role] ?? this.jwtAuth.role;
  }

  getTileClasses(viewItem: Tile): TileClasses {
    return {
      'pharmacy-instructions': viewItem.text === 'Admin Notes',
      'order-remarks-tile': viewItem.orderRemarks?.show
    };
  }

  getTileStyles(viewItem: Tile): TileStyles {
    return {
      'background-color': viewItem.text === 'Admin Notes' ? viewItem.color : ''
    };
  }

  handleHash() {
    if (this.jwtAuth.isAdmin() && this.hash === this.serviceScript.orderData.status_code) {
      this.openDialog();
    }
  }

  removeFragment() {
    this.hash = null;
    this.router.navigate([], {
      relativeTo: this.route,
      fragment: null,
      queryParamsHandling: 'preserve'
    });
  }
}

@Component({
  templateUrl: '../dialogs/change-in-price-dialog.html',
  styleUrls: ['../dialogs/change-in-price-dialog.scss'],
})
export class ChangeInPriceDialog {
  showManualRefundCheck = true;
  showSendEmailCheck = true;
  manualRefund = new FormControl(false);
  sendEmailCheck = new FormControl(true);
  charge = new FormControl('', [
    Validators.required,
    Validators.pattern(new RegExp('ch_|[A-z0-9]{24}')),
  ]);
  constructor(
    private dialogRef: MatDialogRef<ChangeInPriceDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (this.data.message.includes('request')) {
      this.showManualRefundCheck = false;
    } else {
      this.showSendEmailCheck = false;
    }
  }
  onNoClick() {
    this.dialogRef.close();
  }
  onYesClick() {
    if (this.manualRefund.value === true) {
      this.data.manualRefund = 1;
      this.data.stripeCharge = this.charge.value;
    }

    this.data['sendEmail'] = this.sendEmailCheck.value;

    this.dialogRef.close(this.data);
  }
}

@Component({
  selector: 'markup-dialog',
  template: `
    <div mat-dialog-content>
      <h2>Customer Price</h2>
      <mat-divider></mat-divider>
      <div class="mm-mt-2">
        Customer Price is the amount the customer pays for the item including platform
        administration fees.
      </div>
    </div>
    <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
      <button mat-flat-button color="primary" (click)="onNoClick()">Close</button>
    </div>
  `,
})
export class MarkupDialog {
  constructor(
    private dialogRef: MatDialogRef<MarkupDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }
  onNoClick() {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'pharmacy-unit-price-dialog',
  template: `
    <div mat-dialog-content>
      <h2>My Unit Price</h2>
      <mat-divider></mat-divider>
      <div class="mm-mt-2">
        My Unit Price is the amount the pharmacy will be reimbursed for the item.
      </div>
    </div>
    <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
      <button mat-flat-button color="primary" (click)="onNoClick()">Close</button>
    </div>
  `,
})
export class PharmacyUnitPriceDialog {
  constructor(
    private dialogRef: MatDialogRef<PharmacyUnitPriceDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }
  onNoClick() {
    this.dialogRef.close();
  }
}

@Component({
  templateUrl: '../dialogs/resend-form-dialog.html',
  // styleUrls: ['../dialogs/resend-form-dialog.scss']
})
export class ResendFormDialog {
  forms: SurveyData[] = [];
  formsToSubmit: number[];

  constructor(
    private dialogRef: MatDialogRef<ResendFormDialog>,
    @Inject(MAT_DIALOG_DATA) public data: SurveyData[]
  ) {
    data.forEach(form => {
      try {
        form.survey_data = JSON.parse(form.survey_data as unknown as string);
        form.displayText = form.survey_data.survey_data.header.telehealth_type
          .split('-')
          .join(' ');
      } catch (error) { }
    });
    this.forms = data;
  }

  onNoClick() {
    this.dialogRef.close();
  }
  onSubmit() {
    this.dialogRef.close(this.formsToSubmit);
  }
}
// @Component({
//   selector: 'stripe-charge-dialog',
//   template: `
//   <div mat-dialog-content>
//     <h2>Stripe Charge ID</h2>
//     <mat-divider></mat-divider>
//     <div class="mm-mt-2">The Stripe Charge ID can be found under the <b>Events</b> or <b>Connections</b> sections of a payment detail page.</div>
//     </div>
//     <br>
//     <img src='assets/images/charge-id.png'>
//   <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
//     <button mat-flat-button color="primary" (click)="onNoClick()">Close</button>
//   </div>
//   `
// })
// export class StripeChargeDialog {
//   constructor(private dialogRef: MatDialogRef<StripeChargeDialog>, @Inject(MAT_DIALOG_DATA) public data: any) {}
//   onNoClick() {
//     this.dialogRef.close()
//   }
// }

@Component({
  templateUrl: '../dialogs/add-note-dialog.html',
  styleUrls: ['../dialogs/add-note-dialog.scss'],
})
export class AddNoteDialog {
  newNote: FormControl = new FormControl('', [Validators.required]);

  constructor(
    private dialogRef: MatDialogRef<AddNoteDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  onNoClick() {
    this.dialogRef.close();
  }

  onSubmit() {
    const note = this.createNote(this.data.role, this.newNote.value);
    const notes = this.concatenateNotes(note, this.data.existingNotes);
    this.dialogRef.close(notes);
  }

  createNote(author: string, message: string) {
    const timestamp = moment().format('hh:mm A DD MMM YYYY');

    const note = `${author}, ${timestamp}:\n${message}`;
    return note;
  }

  concatenateNotes(newNote: string, existingNotes: string) {
    if (existingNotes) {
      return newNote + '\n\n' + existingNotes;
    } else {
      return newNote;
    }
  }
}
