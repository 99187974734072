import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { User } from 'app/shared/models/user.model';
import { JwtAuthService } from 'app/shared/services/auth/jwt-auth.service';
import { CreateOrderComponent } from 'app/views/orders/create-order/create-order.component';
import {
  CreateOrderOptionsComponent,
  CREATE_ORDER_OPTIONS,
  ALLOWED_TELEHEALTH_LOCATION_STATUSES,
} from './dialogs/create-order-options/create-order-options.component';
import { Router } from '@angular/router';
import { IMenuItem } from 'app/shared/services/navigation.service';
@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.template.html',
  styleUrls: ['./sidenav.component.spec.scss'],
})
export class SidenavComponent {
  @Input('items') public menuItems: IMenuItem[] = [];
  @Input('hasIconMenu') public hasIconTypeMenuItem: boolean;
  @Input('iconMenuTitle') public iconTypeMenuTitle: string;
  user: User = {};
  menuCounts: any;
  Sign = 'plus';
  OrderDetails: any[];

  constructor(
    private jwtAuth: JwtAuthService,
    public dialog: MatDialog,
    private router: Router
  ) {
    this.user = this.jwtAuth.getUser();
  }

  ngOnInit() {
    this.filterTelehealthTab();
  }

  filterTelehealthTab() {
    const locationStatus = this.jwtAuth.getUser().location?.locationstatus;
    if (!ALLOWED_TELEHEALTH_LOCATION_STATUSES.includes(locationStatus)) {
      this.menuItems = this.menuItems.map(menuItem => {
        if (menuItem.state === 'telehealth') return null;
        if (menuItem.state === 'resources') {
          menuItem.sub = menuItem.sub.filter(subItem => subItem.state !== 'telehealth-user-guide');
          if (menuItem.sub.length === 0) return null;
        }
        return menuItem;
      }).filter(menuItem => menuItem !== null);
    }
  }

  openCreateOrderDialog() {
    this.dialog.open(CreateOrderComponent, {
      minWidth: '90%',
      height: '90%',
      minHeight: '90%',
      data: '',
      disableClose: true,
    });
  }

  showCreateOrderOptionsDialog() {
    const dialogRef = this.dialog.open(CreateOrderOptionsComponent, {
      width: '400px',
      maxWidth: '400px',
    });
    dialogRef.afterClosed().subscribe(result => {
      switch (result) {
        case CREATE_ORDER_OPTIONS.pharmacyDelivery:
          this.openCreateOrderDialog();
          break;
        case CREATE_ORDER_OPTIONS.telehealthRequest:
          this.router.navigateByUrl('/telehealth/telehealth-request');
          break;
        default:
          break;
      }
    });
  }
}
